import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../../../utils/getCredentials';
import setCreateFiling from 'scenes/Filings/store/services/setCreateFiling.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const createFiling = createAsyncThunk(
  'filings/createFiling',
  async (filing, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const state = getState();

    const projectId = selectSelectedProjectId(state);

    if (!filing) {
      return;
    }

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    const createdFiling = await setCreateFiling(filing, projectId, params);
    return { createdFiling };
  }
);
