import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProject } from 'store/projects/selectors/projectsSelectors';
import { setSortMultipleInvoicesBySortRule } from '../services/setSortMultipleInvoicesBySortRule';

export const patchSortMultipleInvoicesBySortRule = createAsyncThunk(
  'invoices/patchSortMultipleInvoicesBySortRule',
  async (
    { invoiceSortRuleId, fromDate, toDate, sortFromDate, sortToDate, overrideSort },
    { getState, dispatch }
  ) => {
    const state = getState();

    const selectedProject = selectSelectedProject(state);

    const mainProjectId = selectedProject.masterParent
      ? selectedProject.masterParent
      : selectedProject.id;

    const response = await setSortMultipleInvoicesBySortRule(
      mainProjectId,
      invoiceSortRuleId,
      fromDate,
      toDate,
      sortFromDate,
      sortToDate,
      overrideSort
    );
    console.log('sortMultipleInvoicesBySortRule Response', response);
    return response;
  }
);
