import { createAction } from '@reduxjs/toolkit';

export const openCardDialogWithUserId = createAction('cards/openCardDialog');

export const closeCardDialog = createAction('cards/closeCardDialog');

export const openAddCardDialog = createAction('cards/openAddCardDialog');

export const openAddCardDialogForOtherUser = createAction(
  'cards/openAddCardDialogForOtherUser'
);

export const closeAddCardDialog = createAction('cards/closeAddCardDialog');

export const openDeleteCardDialog = createAction('cards/openDeleteCardDialog');

export const closeDeleteCardDialog = createAction(
  'cards/closeDeleteCardDialog'
);

export const setCardIdToDelete = createAction('cards/setCardIdToDelete');

export const setCardTypeToAdd = createAction('cards/setCardTypeToAdd');

export const openAddNewCompetencyTypeDialog = createAction(
  'cards/openAddNewCompetencyTypeDialog'
);

export const closeAddNewCompetencyTypeDialog = createAction(
  'cards/closeAddNewCompetencyTypeDialog'
);

export const openManageAddedCompetenciesDialog = createAction(
  'cards/openManageAddedCompetenciesDialog'
);

export const closeManageAddedCompetenciesDialog = createAction(
  'cards/closeManageAddedCompetenciesDialog'
);
