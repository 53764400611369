import Config from 'Config';
import { createSignedRequest } from 'utils/createSignedRequest';
import axios from 'axios';

export const getCompanies = async (business_id, lastEvaluatedKey) => {
  const apiPath = `company/${business_id}/companies-get`;

  const body = {
    lastEvaluatedKey: lastEvaluatedKey || undefined,
    limit: 500,
  };

  const request = {
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    // url: `http://localhost:3001/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'POST',
    region: Config.AWS_REGION,
    service: 'execute-api',
    body: JSON.stringify(body),
    data: body,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const signedRequest = await createSignedRequest(request);

  let response = await axios(signedRequest);
  response = response.data;

  return {
    Items: response.Items,
    LastEvaluatedKey: response.LastEvaluatedKey
  }
};