import { createSelector } from '@reduxjs/toolkit';
import {
  selectProjects,
  selectProjectsArray,
  selectProjectsEntitiesWithJoinedData,
  selectSelectedProject,
  selectSelectedProjectId,
} from 'store/projects/selectors/projectsSelectors';

import { groupBy, sortBy, uniq, uniqBy } from 'lodash-es';
import { selectAdminEntities } from 'scenes/Permissions/store/selectors/permissionsSelectors';
import { uniqueResourcesByUserAndEmployer } from 'scenes/WorkDemo/utils/uniqueResourcesByUserAndEmployer';
import { arrayToMap } from 'utils/arrayToMap';
import sort from 'utils/sort';
import {
  selectAllResources,
  selectAllResourcesToMakeAdminOf,
} from '../resourceSliceSelectors';
export const selectResourcesState = (state) => state.resources;

export const selectIsResourcesLoading = createSelector(
  [selectResourcesState],
  (state) => state.loading
);

export const selectIsResourceDeletePending = createSelector(
  [selectResourcesState],
  (state) => state.deleteResourcePending
);

export const selectResourceDeleteError = createSelector(
  [selectResourcesState],
  (state) => state.deleteResourceError
);

export const selectLoadedResourcesProjectIds = createSelector(
  [selectResourcesState],
  (state) => state.loadedResourcesProjectIds
);

// New implementation of storing logged in user resources, where they are seperated from all other resources. Used to make app faster, where user own resources doesn't need to be always filtered from huge amounts of resources data.
export const selectLoggedInUserResourcesEntities = createSelector(
  [selectResourcesState],
  (state) => state.loggedInUserResources.entities
);

export const selectLoggedInUserResourcesArray = createSelector(
  [selectLoggedInUserResourcesEntities],
  (resourceEntities) =>
    Object.values(resourceEntities).filter((resource) => !resource.deletedAt)
);

export const selectCurrentUserResidentResources = createSelector(
  [selectLoggedInUserResourcesArray],
  (resources) =>
    resources.filter((resource) => resource.employmentType === 'RESIDENT')
);

export const selectSelectedProjectCurrentUserResources = createSelector(
  [selectLoggedInUserResourcesArray, selectSelectedProjectId],
  (resources, selectedProjectId) =>
    resources
      ? resources.filter((resource) => resource.project === selectedProjectId)
      : []
);

export const selectSelectedProjectResources = createSelector(
  [selectAllResources, selectSelectedProjectId],
  (resources, selectedProjectId) =>
    resources
      ? resources.filter((resource) => resource.project === selectedProjectId)
      : []
);

export const selectResourcesArrayWithJoinedData = createSelector(
  [selectAllResources, selectProjectsEntitiesWithJoinedData],
  (resources, projects) =>
    resources.map((resource) => ({
      ...resource,
      employer: projects[resource.employer]
        ? projects[resource.employer]
        : { id: resource.employer },
      project: projects[resource.project]
        ? projects[resource.project]
        : { id: resource.project },
    }))
);

export const selectProjectResources = createSelector(
  [selectAllResources, selectProjects, (_, args) => args],
  (resources, projects, args) => {
    const projectId = args;

    {
      let resourceOptions = uniqueResourcesByUserAndEmployer(
        resources.filter((resource) => resource.project === projectId)
      ).map((resource) => {
        if (projects[resource.employer]) {
          resource = { ...resource, employer: projects[resource.employer] };
        } else if (projects[resource.employer]) {
          resource = { ...resource, employer: projects[resource.employer] };
        }
        return resource;
      });

      resourceOptions.sort((a, b) => sort.sortByKey(a, b, 'lastName'));

      return resourceOptions;
    }
  }
);

// selector filters out resource if resources project is created by employer.
export const selectCurrentProjectsResourcesArrayWithJoinedData = createSelector(
  [selectSelectedProjectCurrentUserResources],
  (resources) => {
    return resources.filter((resource) => {
      // cannot leave own employer projects through here
      const isProjectSameAsEmployer = resource.employer === resource.project;
      return !isProjectSameAsEmployer;
    });
  }
);

export const selectResourcesEntitiesWithJoinedData = createSelector(
  [selectResourcesArrayWithJoinedData],
  (resources) => arrayToMap(resources)
);

export const selectIsUserProjectResourcesLoaded = createSelector(
  [selectResourcesState],
  (state) => state.isUserProjectResourcesLoaded
);

export const selectCurrentUserResourcesIndexedByProject = createSelector(
  [selectLoggedInUserResourcesArray],
  (resources) => groupBy(resources, 'project')
);

export const selectLoadingResourcesToMakeAdminOf = createSelector(
  [selectResourcesState],
  (state) => state.loadingResourcesToMakeAdminOf
);

export const selectResourcesToMakeAdminOf = createSelector(
  [selectAllResourcesToMakeAdminOf, selectAdminEntities, (_, args) => args],
  (resources, adminEntities, args) => {
    const businessId = args;
    if (!businessId) {
      return;
    }
    const resourcesUniqueByUser = uniqBy(resources, 'user');
    return resourcesUniqueByUser.map((resource) => {
      console.log(resource.user, businessId);
      return adminEntities[`${resource.user}#${businessId}`]
        ? { ...resource, isAdmin: true }
        : resource;
    });
  }
);

export const selectCurrentUserEmployerProjects = createSelector(
  [selectProjectsArray, selectLoggedInUserResourcesArray],
  (projects, userResources) => {
    const userResourceEmployerIds = uniq(
      userResources.map((resource) => resource.employer)
    );

    const userEmployerProjects = projects.filter((project) =>
      userResourceEmployerIds.includes(project.id)
    );

    return userEmployerProjects;
  }
);

export const selectEmployeeResourcesOfSelectedEmployerBusinessByAdminStatus =
  createSelector(
    [selectAllResources, selectSelectedProject, selectAdminEntities],
    (resources, selectedProject, adminEntities) => {
      const projectBusinessId = selectedProject.business_id;

      const resourcesEmployedByBusiness = resources.filter(
        (resource) =>
          resource.employerBusinessId === projectBusinessId ||
          resource.employer === selectedProject.id
      );

      const uniqueUserResources = uniqBy(resourcesEmployedByBusiness, 'user');

      let adminResources = [];
      let normalResources = [];

      uniqueUserResources.forEach((resource) => {
        if (adminEntities[`${resource.user}#${projectBusinessId}`]) {
          adminResources.push(resource);
        } else {
          normalResources.push(resource);
        }
      });

      return {
        adminResources: sortBy(adminResources, ['firstName']),
        normalResources: sortBy(normalResources, ['firstName']),
      };
    }
  );
