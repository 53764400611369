import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import dayjs from 'dayjs';
import { uniq } from 'lodash';
import { getAdminWorkCalendarDataForProjectInTimeFrame } from 'scenes/WorkDemo/services/getAdminWorkCalendarDataForProjectInTimeFrame.service';
import { createRequestGetAdminWorkCalendarDataForProjectInTimeFrame } from 'scenes/WorkDemo/utils/requests/createRequestGetAdminCalendarDataInTimeframe';
import { loadAssignmentsForProjects } from 'store/assignments/thunks/loadAssignmentsForProjects';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { createSignedRequest } from '../../utils/createSignedRequest';
import getCredentials from '../../utils/getCredentials';
import { loadRecordAllowancesOfEmployerInTimeframe } from 'store/recordAllowances/thunks/loadRecordAllowancesOfEmployerInTimeframe.thunk';

export const loadAdminCalendarDataInTimeframe = createAsyncThunk(
  'workCalendar/loadAdminCalendarDataInTimeframe',
  async (_, { getState, dispatch }) => {
    const state = getState();

    const { workSearchParams_demo } = state;

    const selectedProjectId = selectSelectedProjectId(state);

    const fromDate = dayjs(workSearchParams_demo.fromDate);
    const toDate = dayjs(workSearchParams_demo.toDate);

    const searchMonthDifference = toDate.diff(fromDate, 'month');

    let calendarData;

    dispatch(
      loadRecordAllowancesOfEmployerInTimeframe({
        fromDate: workSearchParams_demo.fromDate,
        toDate: workSearchParams_demo.toDate,
      })
    );

    if (searchMonthDifference > 0) {
      // this returns the actual span of months that need to be taken into consideration
      const searchMonthDifferenceRoundedUp = Math.ceil(
        toDate.diff(fromDate, 'month', true)
      );

      const { accessKeyId, secretAccessKey, sessionToken } =
        await getCredentials();

      let monthlySearches = [];

      monthlySearches.push({
        fromDate: fromDate.toISOString(),
        toDate: fromDate.endOf('month').toISOString(),
      });

      for (let i = 1; i <= searchMonthDifferenceRoundedUp; i++) {
        let nextMonth = fromDate.add(i, 'month');

        const isSameAsEndMonth = toDate.isSame(nextMonth, 'month');

        let startOfNextMonth = nextMonth.startOf('month').toISOString();

        let endOfNextMonth = isSameAsEndMonth
          ? toDate.toISOString()
          : nextMonth.endOf('month').toISOString();

        monthlySearches.push({
          fromDate: startOfNextMonth,
          toDate: endOfNextMonth,
        });
      }

      console.log('monthly searches', monthlySearches);

      const requests = await Promise.all(
        monthlySearches.map((monthlySearch) => {
          const request =
            createRequestGetAdminWorkCalendarDataForProjectInTimeFrame(
              selectedProjectId,
              monthlySearch.fromDate,
              monthlySearch.toDate
            );
          return createSignedRequest(
            request,
            accessKeyId,
            secretAccessKey,
            sessionToken
          );
        })
      );

      calendarData = await Promise.all(
        requests.map((request) => axios(request).then((res) => res.data))
      );
    } else {
      const data = await getAdminWorkCalendarDataForProjectInTimeFrame(
        selectedProjectId,
        workSearchParams_demo.fromDate,
        workSearchParams_demo.toDate
      );

      calendarData = [data];
    }

    const recordsProjectIds = uniq(
      calendarData
        .map((data) => data.records)
        .filter((record) => record.assignment)
        .map((record) => record.project)
    );

    dispatch(loadAssignmentsForProjects(recordsProjectIds));

    return calendarData;
  }
);
