import { createAsyncThunk } from '@reduxjs/toolkit';
import { postUpdateProjectDatesFromFilings } from '../services/postUpdateProjectDatesFromFilings.service';

export const updateProjectDatesFromFilings = createAsyncThunk(
  'projects/updateProjectDatesFromFilings',
  async (projectData) => {

    const { id, startAt, endAt } = projectData;

    if (!id) {
      throw new Error('No project selected');
    }

    if (!startAt || !endAt) {
      throw new Error('Start and end dates are required in updateProjectDatesFromFilings thunk');
    }

    projectData = { id, startAt, endAt }; // Limit this endpoint to updating only startAt and endAt

    const projectUpdateData = await postUpdateProjectDatesFromFilings(projectData);

    return projectUpdateData;
  }
);
