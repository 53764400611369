import { createAsyncThunk } from '@reduxjs/toolkit';
import { PROJECT_BANK_TILAAJAVASTUU_DOCUMENTS_VIEW } from 'scenes/Permissions/constants/permissions';
import { selectIsRequiredPermission } from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';
import { getProjectAndSubProjectTilaajavastuuDocuments } from 'scenes/ProjectBank/services/getProjectAndSubProjectTilaajavastuuDocuments.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const loadProjectAndSubProjectTilaajavastuuDocuments = createAsyncThunk(
  'projectBank/loadProjectAndSubProjectTilaajavastuuDocuments',
  async (_, { getState }) => {
    const selectedProjectId = selectSelectedProjectId(getState());

    const isRequiredPermission = selectIsRequiredPermission(
      getState(),
      PROJECT_BANK_TILAAJAVASTUU_DOCUMENTS_VIEW
    );

    if (isRequiredPermission) {
      const tilaajavastuuDocuments =
        await getProjectAndSubProjectTilaajavastuuDocuments(selectedProjectId);

      return tilaajavastuuDocuments;
    } else {
      return [];
    }
  }
);
