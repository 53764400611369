import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import dayjs from 'dayjs';
import { uniq } from 'lodash';
import { postGetAdminWorkCalendarDataForProjectInTimeFrameWithSearchParams } from 'scenes/WorkDemo/services/postGetAdminWorkCalendarDataForProjectInTimeFrameWithSearchParams.service';
import { createRequestPostGetAdminWorkCalendarDataForProjectInTimeframeWithSearchParams } from 'scenes/WorkDemo/utils/requests/createRequestPostGetAdminWorkCalendarDataForProjectInTimeFrameWithSearchParams';
import { loadAssignmentsForProjects } from 'store/assignments/thunks/loadAssignmentsForProjects';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { loadRecordAllowancesOfEmployerInTimeframe } from 'store/recordAllowances/thunks/loadRecordAllowancesOfEmployerInTimeframe.thunk';
import { createSignedRequest } from '../../utils/createSignedRequest';
import getCredentials from '../../utils/getCredentials';
import { selectWorkCalendarSearchFilters } from '../selectors/workSelectorsDemo';

export const loadAdminCalendarDataInTimeframeWithSearchParams =
  createAsyncThunk(
    'workCalendar/loadAdminCalendarDataInTimeframeWithSearchParams',
    async (_, { getState, dispatch }) => {
      const state = getState();

      const { workSearchParams_demo } = state;

      const selectedProjectId = selectSelectedProjectId(state);

      const fromDate = dayjs(workSearchParams_demo.fromDate);
      const toDate = dayjs(workSearchParams_demo.toDate);

      const searchParams = selectWorkCalendarSearchFilters(state);

      const searchMonthDifference = toDate.diff(fromDate, 'month');

      let calendarData;

      dispatch(
        loadRecordAllowancesOfEmployerInTimeframe({
          fromDate: workSearchParams_demo.fromDate,
          toDate: workSearchParams_demo.toDate,
        })
      );

      if (searchMonthDifference > 0) {
        const { accessKeyId, secretAccessKey, sessionToken } =
          await getCredentials();

        // this returns the actual span of months that need to be taken into consideration
        const searchMonthDifferenceRoundedUp = Math.ceil(
          toDate.diff(fromDate, 'month', true)
        );

        let monthlySearches = [];

        monthlySearches.push({
          fromDate: fromDate.toISOString(),
          toDate: fromDate.endOf('month').toISOString(),
        });

        for (let i = 1; i <= searchMonthDifferenceRoundedUp; i++) {
          let nextMonth = fromDate.add(i, 'month');

          const isSameAsEndMonth = toDate.isSame(nextMonth, 'month');

          let startOfNextMonth = nextMonth.startOf('month').toISOString();

          let endOfNextMonth = isSameAsEndMonth
            ? toDate.toISOString()
            : nextMonth.endOf('month').toISOString();

          monthlySearches.push({
            fromDate: startOfNextMonth,
            toDate: endOfNextMonth,
          });
        }

        console.log('monthly searches', monthlySearches);

        const requests = await Promise.all(
          monthlySearches.map((monthlySearch) => {
            const request =
              createRequestPostGetAdminWorkCalendarDataForProjectInTimeframeWithSearchParams(
                selectedProjectId,
                monthlySearch.fromDate,
                monthlySearch.toDate,
                searchParams
              );
            return createSignedRequest(
              request,
              accessKeyId,
              secretAccessKey,
              sessionToken
            );
          })
        );

        calendarData = await Promise.all(
          requests.map((request) => axios(request).then((res) => res.data))
        );
      } else {
        const data =
          await postGetAdminWorkCalendarDataForProjectInTimeFrameWithSearchParams(
            selectedProjectId,
            workSearchParams_demo.fromDate,
            workSearchParams_demo.toDate,
            searchParams
          );

        calendarData = [data];
      }

      const recordsProjectIds = uniq(
        calendarData
          .map((data) => data.records)
          .flat()
          .map((record) => record.project)
      );

      dispatch(loadAssignmentsForProjects(recordsProjectIds));

      return calendarData;
    }
  );
