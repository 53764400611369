import { createSelector } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import {
  selectProjects,
  selectSelectedProject,
} from 'store/projects/selectors/projectsSelectors';
import { arrayToMap } from 'utils/arrayToMap';

const selectAssignmentsState = (state) => state.assignments;

export const selectAssignments = createSelector(
  [selectAssignmentsState],
  (state) => state.assignments.entities
);

export const selectAssignmentsArray = createSelector(
  [selectAssignments],
  (assignments) => Object.values(assignments)
);

export const selectAssignmentsArrayWithoutDeleted = createSelector(
  [selectAssignmentsArray],
  (assignments) => assignments.filter((assignment) => !assignment.deletedAt)
);

export const selectAssignmentEntitiesWithoutDeleted = createSelector(
  [selectAssignmentsArrayWithoutDeleted],
  (assignments) => arrayToMap(assignments)
);

export const selectProjectAssignments = createSelector(
  [selectAssignmentEntitiesWithoutDeleted, selectProjects, (_, args) => args],
  (assignments, projects, args) => {
    const projectId = args;
    const projectAssignments = Object.values(assignments).filter(
      (assignment) => assignment.project === projectId
    );
    return projectAssignments.map((assignment) => ({
      ...assignment,
      parent: assignments[assignment.parent],
      project: projects[assignment.project],
    }));
  }
);

export const selectChildAssignmentsToAdd = createSelector(
  [selectAssignmentsState],
  (state) => state.childAssignmentsToAdd.entities
);
export const selectAssignmentModifications = createSelector(
  [selectAssignmentsState],
  (state) => state.assignmentModifications
);

export const selectChildAssignmentsToAddArray = createSelector(
  [selectChildAssignmentsToAdd],
  (assignments) => Object.values(assignments)
);

export const selectChildAssignmentsToAddWithModifications = createSelector(
  [selectChildAssignmentsToAddArray, selectAssignmentModifications],
  (assignments, assignmentModifications) => {
    return assignments.map((assignment) => ({
      ...assignment,
      ...assignmentModifications[assignment.id],
    }));
  }
);

export const selectAssignmentChildrenByParentId = createSelector(
  [selectAssignmentsArrayWithoutDeleted, (_, args) => args],
  (assignments, args) => {
    const parentId = args;

    return assignments.filter((assignment) => assignment.parent === parentId);
  }
);

export const selectAssignmentChildrenToAddByParentId = createSelector(
  [selectChildAssignmentsToAddWithModifications, (_, args) => args],
  (newChildAssignments, args) => {
    const parentId = args;

    return newChildAssignments.filter(
      (assignment) => assignment.parent === parentId
    );
  }
);

export const selectTypedAssignments = createSelector(
  [selectAssignmentsArray],
  (assignments) => arrayToMap(assignments)
);

export const selectSelectedProjectsAssignmentsArrayWithoutDeleted =
  createSelector(
    [selectAssignmentsArrayWithoutDeleted, selectSelectedProject],
    (assignments, selectedProject) => {
      return assignments.filter(
        (assignment) => assignment.project === selectedProject.id
      );
    }
  );

export const selectAssignmentsByProjectId = (projectId) => {
  return createSelector(
    [selectAssignmentsArrayWithoutDeleted],
    (assignments) =>
      assignments.filter((assignment) => assignment.project === projectId)
  );
} 

export const selectSelectedProjectsAssignmentsWithModifications =
  createSelector(
    [
      selectSelectedProjectsAssignmentsArrayWithoutDeleted,
      selectAssignmentModifications,
    ],
    (assignments, assignmentModifications) =>
      assignments.map((assignment) => ({
        ...assignment,
        ...assignmentModifications[assignment.id],
      }))
  );

export const selectSelectedProjectAssignmentsTree = createSelector(
  [selectSelectedProjectsAssignmentsWithModifications],
  (assignments) => {
    const parentAssignments = assignments.filter(
      (assignment) => !assignment.parent
    );

    const assignmentTree = nestedAssigmentTree(parentAssignments, assignments);

    const sortedAssignments = sortBy(assignmentTree, ['number']);

    return sortedAssignments;
  }
);

function nestedAssigmentTree(assignmentParents, allAssignments) {
  function nestedAssignments(parentAssignment, assignments) {
    const assignmentChildren = assignments.filter(
      (a) => a.parent === parentAssignment.id
    );
    const assignmentHasChildren = assignmentChildren.length > 0;

    let children = [];

    if (assignmentHasChildren) {
      children = assignmentChildren.map((a) =>
        nestedAssignments(a, assignments)
      );
    }
    let assignmentTree = {
      ...parentAssignment,
      children,
    };

    return assignmentTree;
  }

  const nestedAssignmentOptions = assignmentParents.map((a) =>
    nestedAssignments(a, allAssignments)
  );

  return nestedAssignmentOptions;
}

export const selectIsAssignmentsLoaded = createSelector(
  [selectAssignmentsState],
  (state) => state.assignmentsLoaded
);

export const selectIsAssignmentsLoading = createSelector(
  [selectAssignmentsState],
  (state) => state.loading
);

export const selectRootAssignmentsToAdd = createSelector(
  [selectAssignmentsState],
  (state) => state.rootAssignmentsToAdd.entities
);

export const selectRootAssignmentsToAddArray = createSelector(
  [selectRootAssignmentsToAdd],
  (rootAssignments) => Object.values(rootAssignments)
);

export const selectRootAssignmentsToAddArrayWithModifications = createSelector(
  [selectRootAssignmentsToAddArray, selectAssignmentModifications],
  (assignments, assignmentModifications) => {
    return assignments.map((assignment) => {
      return {
        ...assignment,
        ...assignmentModifications[assignment.id],
      };
    });
  }
);

export const selectAllNewAssignmentsToCreate = createSelector(
  [
    selectRootAssignmentsToAddArrayWithModifications,
    selectChildAssignmentsToAddWithModifications,
  ],
  (rootAssignments, childAssignments) => [
    ...rootAssignments,
    ...childAssignments,
  ]
);
