import { createSlice } from '@reduxjs/toolkit';
import { createEntityAdapter } from '@reduxjs/toolkit';
import { loadFilings } from './store/thunks/loadFilings.thunk';
import { reloadFilings } from './store/thunks/reloadFilings.thunk';
import { createFiling } from './store/thunks/createFiling.thunk';
import { updateFiling } from './store/thunks/updateFiling.thunk';
import { deleteFiling } from './store/thunks/deleteFiling.thunk';
import { sendFiling } from './store/thunks/sendFiling.thunk'
import { requestCorrectionFiling } from './store/thunks/requestCorrectionFiling.thunk';
import * as filingsActions from './store/actions/filingsActions';
import { loadCompanies } from './store/thunks/loadCompanies.thunk';

const filingsAdapter = createEntityAdapter();
const companiesAdapter = createEntityAdapter();

const resetState = {
  filings: createEntityAdapter().getInitialState(),
  companies: createEntityAdapter().getInitialState(),
};

const filingsSlice = createSlice({
  name: 'filings',
  initialState: {
    filings: filingsAdapter.getInitialState(),
    companies: companiesAdapter.getInitialState(),
  },
  reducers: {},
  extraReducers(builder) {
    builder
      // LOAD filings:
      .addCase(loadFilings.pending, (state) => ({
        ...state,
        loadingFilings: true,
      }))
      .addCase(loadFilings.fulfilled, (state, action) => {
        state.loadingFilings = false;
        filingsAdapter.setMany(state.filings, action.payload.filings);
      })
      .addCase(loadFilings.rejected, (state, action) => ({
        ...state,
        loadingFilings: false,
        error: action.error,
      }))
      // RELOAD filings:
      .addCase(reloadFilings.pending, (state) => ({
        ...state,
        reloadFilingsLoading: true,
      }))
      .addCase(reloadFilings.fulfilled, (state, action) => {
        state.reloadFilingsLoading = false;
        filingsAdapter.setAll(state.filings, action.payload.filings);
      })
      .addCase(reloadFilings.rejected, (state, action) => ({
        ...state,
        reloadFilingsLoading: false,
        error: action.error,
      }))
      // RESET filings:
      .addCase(filingsActions.resetFilings, (state, action) => ({
        ...state,
        ...resetState,
      }))
      // LOAD COMPANIES:
      .addCase(loadCompanies.pending, (state) => ({
        ...state,
        loadingCompanies: true,
      }))
      .addCase(loadCompanies.fulfilled, (state, action) => {
        state.loadingCompanies = false;
        companiesAdapter.setMany(state.companies, action.payload.companies);
      })
      .addCase(loadCompanies.rejected, (state, action) => ({
        ...state,
        loadingCompanies: false,
        error: action.error,
      }))
      // CREATE filing:
      .addCase(createFiling.pending, (state) => ({
        ...state,
        createFilingLoading: true,
      }))
      .addCase(createFiling.fulfilled, (state, action) => {
        state.createFilingLoading = false;
        filingsAdapter.upsertOne(
          state.filings,
          action.payload.createdFiling
        );
      })
      .addCase(createFiling.rejected, (state, action) => ({
        ...state,
        createFilingLoading: false,
        error: action.error,
      }))
      // UPDATE filing:
      .addCase(updateFiling.pending, (state) => ({
        ...state,
        updateFilingLoading: true,
      }))
      .addCase(updateFiling.fulfilled, (state, action) => {
        state.updateFilingLoading = false;
        filingsAdapter.upsertOne(
          state.filings,
          action.payload.updatedFiling
        );
      })
      .addCase(updateFiling.rejected, (state, action) => ({
        ...state,
        updateFilingLoading: false,
        error: action.error,
      }))
      // REQUEST CORRECTION filing:
      .addCase(requestCorrectionFiling.pending, (state) => ({
        ...state,
        requestCorrectionFilingLoading: true,
      }))
      .addCase(requestCorrectionFiling.fulfilled, (state, action) => {
        state.requestCorrectionFilingLoading = false;
        filingsAdapter.upsertOne(
          state.filings,
          action.payload.createdFiling
        );
      })
      .addCase(requestCorrectionFiling.rejected, (state, action) => ({
        ...state,
        requestCorrectionFilingLoading: false,
        error: action.error,
      }))
      // DELETE filing:
      .addCase(deleteFiling.pending, (state) => ({
        ...state,
        deleteFilingLoading: true,
      }))
      .addCase(deleteFiling.fulfilled, (state, action) => {
        state.deleteFilingLoading = false;
        filingsAdapter.removeOne(
          state.filings,
          action.payload.deletedFiling.id
        );
      })
      .addCase(deleteFiling.rejected, (state, action) => ({
        ...state,
        deleteFilingLoading: false,
        error: action.error,
      }))
      // SEND filing:
      .addCase(sendFiling.pending, (state) => ({
        ...state,
        sendFilingLoading: true,
      }))
      .addCase(sendFiling.fulfilled, (state, action) => {
        state.sendFilingLoading = false;
        // filingsAdapter.upsertOne(
        //   state.filings,
        //   action.payload.sentFiling
        // );
      })
      .addCase(sendFiling.rejected, (state, action) => ({
        ...state,
        sendFilingLoading: false,
        error: action.error,
      }))
  },
});

export const {
  selectAll: selectAllFilings,
  selectEntities: selectFilingEntities,
  selectById: selectFilingById,
  selectIds: selectFilingIds,
} = filingsAdapter.getSelectors((state) => state.filings.filings);

export const {
  selectAll: selectAllCompanies,
  selectEntities: selectCompanyEntities,
  selectById: selectCompanyById,
  selectIds: selectCompanyIds,
} = companiesAdapter.getSelectors((state) => state.filings.companies);

export default filingsSlice.reducer;
