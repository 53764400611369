import { createAsyncThunk } from '@reduxjs/toolkit';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { selectSelectedTilaajavastuuFolderProjectId } from '../selectors/project-bank.selectors';
import { getProjectTilaajavastuuDocuments } from 'scenes/ProjectBank/services/getProjectTilaajavastuuDocuments.service';

export const loadProjectTilaajavastuuDocuments = createAsyncThunk(
  'projectBank/loadProjectTilaajavastuuDocuments',
  async (_, { getState }) => {
    const selectedTilaajavastuuFolderProjectId =
      selectSelectedTilaajavastuuFolderProjectId(getState());
    const selectedProjectId = selectSelectedProjectId(getState());

    const effectiveProjectId = selectedTilaajavastuuFolderProjectId
      ? selectedTilaajavastuuFolderProjectId
      : selectedProjectId;

    const tilaajavastuuDocuments = await getProjectTilaajavastuuDocuments(
      effectiveProjectId
    );

    return tilaajavastuuDocuments;
  }
);
