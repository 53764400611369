import { createSelector } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { selectSelectedRecord } from 'store/records/selectors/records.selectors';
import { selectLoggedInUserResourcesEntities } from 'store/resources/selectors/resources.selectors';
import { selectEmployerAllowancesArray } from './employerAllowances.selectors';

export const selectSelectedClockOutRecordResourceEmployerAllowances =
  createSelector(
    [
      selectSelectedRecord,
      selectEmployerAllowancesArray,
      selectLoggedInUserResourcesEntities,
    ],
    (recordToClockOut, employerAllowances, resources) => {
      const recordResource = resources[recordToClockOut.resource];
      let recordResourceBusinessId;

      if (recordResource && recordResource.employerBusinessId) {
        recordResourceBusinessId = recordResource.employerBusinessId;
      } else {
        return [];
      }

      const recordEmployerBusinessAllowances = employerAllowances.filter(
        (allowance) => allowance.businessId === recordResourceBusinessId
      );

      const employerBusinessAllowancesWithName =
        recordEmployerBusinessAllowances.map((allowance) => ({
          ...allowance,
          salaryName: i18next.t(
            `common:report.salaries.${allowance.salaryType.toLowerCase()}`
          ),
        }));

      return employerBusinessAllowancesWithName;
    }
  );
