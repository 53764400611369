import { createAsyncThunk } from '@reduxjs/toolkit';
import { patchTilaajavastuuDocumentAsDeleted } from 'scenes/ProjectBank/services/patchTilaajavastuuDocumentAsDeleted.service';
import { selectSelectedPreviewTilaajavastuuDocument } from '../selectors/project-bank.selectors';

export const deleteTilaajavastuuDocument = createAsyncThunk(
  'projectBank/deleteTilaajavastuuDocument',
  async (_, { getState }) => {
    const selectedTilaajavastuuDocument =
      selectSelectedPreviewTilaajavastuuDocument(getState());

    const { projectId, businessId } = selectedTilaajavastuuDocument;

    const updatedTilaajavastuuDocumentItem =
      await patchTilaajavastuuDocumentAsDeleted(projectId, businessId);

    return updatedTilaajavastuuDocumentItem;
  }
);
