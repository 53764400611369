import { createAsyncThunk } from '@reduxjs/toolkit';
import updateProjectFromIlveService from '../services/updateProjectFromIlve.service';
import { selectSelectedProjectId } from '../selectors/projectsSelectors';

/**
 * Update project thunk
 * @param {object} projectData - the project data to be added or updated to project
 */
export const updateProjectFromIlve = createAsyncThunk(
  'projects/updateProjectFromIlve',
  async (projectData, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    if (!selectedProjectId) {
      throw new Error('No project selected');
    }

    if (!projectData) {
      throw new Error('Project is required in updateProject thunk');
    }

    if (projectData.id !== selectedProjectId) {
      throw new Error('Project id mismatch');
    }

    const updatedProject = await updateProjectFromIlveService(
      selectedProjectId,
      {
        project: { ...projectData },
      }
    );

    return updatedProject;
  }
);
