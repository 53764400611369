import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { enqueueSnackbar } from 'notistack';
import {
  closeAddCardDialog,
  closeAddNewCompetencyTypeDialog,
  closeCardDialog,
  closeDeleteCardDialog,
  closeManageAddedCompetenciesDialog,
  openAddCardDialog,
  openAddCardDialogForOtherUser,
  openAddNewCompetencyTypeDialog,
  openCardDialog,
  openCardDialogWithUserId,
  openDeleteCardDialog,
  openManageAddedCompetenciesDialog,
  setCardTypeToAdd,
  setUserIdForCardDialog,
} from './actions/cards.actions';
import { addCardForCurrentUser } from './thunks/addCardForCurrentUserThunk';
import { addCardForOtherUser } from './thunks/addCardForOtherUser.thunk';
import { deleteCard } from './thunks/deleteCard.thunk';
import { loadCurrentUserCards } from './thunks/loadCurrentUserCards.thunk';
import { loadExpiringCardInfoOfEmployeesOfEmployer } from './thunks/loadExpiringCardInfoOfEmployeesInBusiness.thunk';
import { loadExpiringCardsOfOwnBusinessEmployees } from './thunks/loadExpiringCardsOfOwnBusinessEmployees.thunk';
import { loadValidCardsOfUser } from './thunks/loadValidCardsOfUser.thunk';
import i18n from 'i18next';
import { loadBusinessCompetencyTypes } from './thunks/loadBusinessCompetencyTypes.thunk';
import { deleteCompetencyType } from './thunks/deleteCompetencyType.thunk';
import { addNewCompetencyType } from './thunks/addNewCompetencyType.thunk';
const cardsAdapter = createEntityAdapter();

const expiringEmployeeCardsAdapter = createEntityAdapter();

const competencyTypesAdapter = createEntityAdapter();

const cardsSlice = createSlice({
  name: 'cards',
  initialState: {
    cards: cardsAdapter.getInitialState(),
    competencyTypes: competencyTypesAdapter.getInitialState(),
    expiringEmployeeCards: expiringEmployeeCardsAdapter.getInitialState(),
    userIdToAddCardFor: '',
    cardTypeToAdd: '',
    cardIdToDelete: '',
    loading: false,
    validCardsLoaded: false,
    isAddCardForOtherUserDialogOpen: false,
    isAddingCard: false,
    isDeletingCard: false,
    userIdForCardDialog: '',
    isCardDialogOpen: false,
    isDeleteCardDialogOpen: false,
    isAddCardDialogOpen: false,
    isAddNewCompetencyTypeDialogOpen: false,
    isManageAddedCompetenciesDialogOpen: false,
    isAddingNewCompetencyType: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadCurrentUserCards.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadCurrentUserCards.fulfilled, (state, action) => {
        cardsAdapter.setMany(state.cards, action.payload);
        state.loading = false;
      })
      .addCase(loadCurrentUserCards.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(loadValidCardsOfUser.fulfilled, (state, action) => {
        cardsAdapter.setMany(state.cards, action.payload);
        state.validCardsLoaded = true;
      })
      .addCase(addCardForCurrentUser.pending, (state, action) => {
        state.isAddingCard = true;
      })
      .addCase(addCardForCurrentUser.fulfilled, (state, action) => {
        cardsAdapter.setOne(state.cards, action.payload);
        state.isAddingCard = false;
        state.isAddCardDialogOpen = false;
        enqueueSnackbar('Kortti lisätty onnistuneesti', {
          variant: 'success',
        });
      })
      .addCase(addCardForCurrentUser.rejected, (state, action) => {
        state.isAddingCard = false;
        enqueueSnackbar('Kortin lisäys epäonnistui', {
          variant: 'error',
        });
      })
      .addCase(addCardForOtherUser.pending, (state, action) => {
        state.isAddingCard = true;
      })
      .addCase(addCardForOtherUser.fulfilled, (state, action) => {
        cardsAdapter.setOne(state.cards, action.payload);
        state.isAddingCard = false;
        state.isAddCardForOtherUserDialogOpen = false;
        state.userIdToAddCardFor = '';
        enqueueSnackbar('Kortti lisätty onnistuneesti', {
          variant: 'success',
        });
      })
      .addCase(addCardForOtherUser.rejected, (state, action) => {
        state.isAddingCard = false;
        enqueueSnackbar('Kortin lisäys epäonnistui', {
          variant: 'error',
        });
      })
      .addCase(openCardDialogWithUserId, (state, action) => {
        state.isCardDialogOpen = true;
        state.userIdForCardDialog = action.payload;
      })
      .addCase(closeCardDialog, (state, action) => {
        state.isCardDialogOpen = false;
        state.userIdForCardDialog = '';
      })
      .addCase(openAddCardDialog, (state, action) => {
        state.isAddCardDialogOpen = true;
      })

      .addCase(openAddCardDialogForOtherUser, (state, action) => {
        state.isAddCardForOtherUserDialogOpen = true;
        state.userIdToAddCardFor = action.payload;
      })

      .addCase(closeAddCardDialog, (state, action) => {
        state.isAddCardDialogOpen = false;
        state.isAddCardForOtherUserDialogOpen = false;
        state.userIdToAddCardFor = '';
        state.cardTypeToAdd = '';
      })
      .addCase(
        loadExpiringCardInfoOfEmployeesOfEmployer.fulfilled,
        (state, action) => {
          expiringEmployeeCardsAdapter.setMany(
            state.expiringEmployeeCards,
            action.payload
          );
        }
      )
      .addCase(
        loadExpiringCardsOfOwnBusinessEmployees.fulfilled,
        (state, action) => {
          expiringEmployeeCardsAdapter.setMany(
            state.expiringEmployeeCards,
            action.payload
          );
        }
      )
      .addCase(openDeleteCardDialog, (state, action) => {
        state.isDeleteCardDialogOpen = true;

        state.cardIdToDelete = action.payload;
      })
      .addCase(closeDeleteCardDialog, (state, action) => {
        state.isDeleteCardDialogOpen = false;
        state.cardIdToDelete = '';
      })
      .addCase(deleteCard.pending, (state, action) => {
        state.isDeletingCard = true;
      })
      .addCase(deleteCard.fulfilled, (state, action) => {
        cardsAdapter.setOne(state.cards, action.payload);
        state.isDeleteCardDialogOpen = false;
        state.cardIdToDelete = '';
        state.isDeletingCard = false;
        enqueueSnackbar(i18n.t('notifications.success.delete-card-success'), {
          variant: 'success',
        });
      })
      .addCase(deleteCard.rejected, (state, action) => {
        state.isDeletingCard = false;
        enqueueSnackbar(i18n.t('notifications.errors.delete-card-rejected'), {
          variant: 'error',
        });
      })
      .addCase(loadBusinessCompetencyTypes.fulfilled, (state, action) => {
        competencyTypesAdapter.setMany(state.competencyTypes, action.payload);
      })
      .addCase(deleteCompetencyType.fulfilled, (state, action) => {
        competencyTypesAdapter.removeOne(
          state.competencyTypes,
          action.payload.id
        );
        state.cardTypeToAdd = '';
        enqueueSnackbar('Pätevyystyyppi poistettu onnistuneesti', {
          variant: 'success',
        });
      })
      .addCase(setCardTypeToAdd, (state, action) => {
        state.cardTypeToAdd = action.payload;
      })
      .addCase(openAddNewCompetencyTypeDialog, (state, action) => {
        state.isAddNewCompetencyTypeDialogOpen = true;
      })
      .addCase(closeAddNewCompetencyTypeDialog, (state, action) => {
        state.isAddNewCompetencyTypeDialogOpen = false;
      })
      .addCase(addNewCompetencyType.pending, (state, action) => {
        state.isAddingNewCompetencyType = true;
      })
      .addCase(addNewCompetencyType.fulfilled, (state, action) => {
        const createdBusinessCompetencies = action.payload;

        competencyTypesAdapter.setMany(
          state.competencyTypes,
          createdBusinessCompetencies
        );
        state.cardTypeToAdd = `${createdBusinessCompetencies[0].type}#${createdBusinessCompetencies[0].name}`;
        state.isAddNewCompetencyTypeDialogOpen = false;
        state.isAddingNewCompetencyType = false;

        enqueueSnackbar(
          i18n.t('notifications.success.add_competency_type_success'),
          {
            variant: 'success',
          }
        );
      })
      .addCase(addNewCompetencyType.rejected, (state, action) => {
        enqueueSnackbar(
          i18n.t('notifications.success.add_competency_type_rejected'),
          {
            variant: 'error',
          }
        );
        state.isAddingNewCompetencyType = false;
      })
      .addCase(openManageAddedCompetenciesDialog, (state, action) => {
        state.isManageAddedCompetenciesDialogOpen = true;
      })
      .addCase(closeManageAddedCompetenciesDialog, (state, action) => {
        state.isManageAddedCompetenciesDialogOpen = false;
      });
  },
});

export default cardsSlice.reducer;
