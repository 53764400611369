import {
  BULLETIN_BOARD_ACCESS,
  BULLETIN_BOARD_EDIT_ALL,
  BULLETIN_BOARD_SEND,
  BULLETIN_CREATE_EDIT_OWN,
  EDIT_OTHER_USERS_RECORDS,
  EDIT_PERMISSIONS_OF_PROJECT,
  EXPENSE_TRACKING_ACCESS,
  FORMS_ACCESS,
  FORMS_CREATE_EDIT_OWN,
  FORMS_EDIT_ALL,
  FORMS_MANAGE_TEMPLATES,
  FORMS_SEND,
  FORMS_ORIENTATION_FORMS_MANAGE,
  GALLERY_ACCESS,
  GENERATE_REPORT,
  PROJECT_APPROVALS_MANAGE,
  PROJECT_BANK_DELETE,
  PROJECT_CREATE,
  PROJECT_TIMELINES_EDIT,
  PROJECT_TIMELINES_VIEW,
  PROJECT_EDIT,
  RECORDS_EDIT_OWN_RECORDS,
  RECORDS_SEND_TO_APPROVAL,
  VIEW_OTHER_RESOURCES,
  VIEW_OTHER_USERS_RECORDS,
  VIEW_SUBPROJECTS,
  ORIENTATION_QUIZ_MANAGE,
  COMPETENCIES_VIEW_OTHER_USER_COMPETENCIES,
  PROJECT_BANK_TILAAJAVASTUU_DOCUMENTS_VIEW,
  PROJECT_BANK_TILAAJAVASTUU_DOCUMENTS_MANAGE,
} from './permissions';

export const PROJECT_TOGGLABLE_PERMISSIONS = [
  GENERATE_REPORT,
  VIEW_OTHER_RESOURCES,
  RECORDS_EDIT_OWN_RECORDS,
  EDIT_OTHER_USERS_RECORDS,
  VIEW_OTHER_USERS_RECORDS,
  EDIT_PERMISSIONS_OF_PROJECT,
  BULLETIN_BOARD_ACCESS,
  BULLETIN_BOARD_EDIT_ALL,
  BULLETIN_CREATE_EDIT_OWN,
  BULLETIN_BOARD_SEND,
  FORMS_ACCESS,
  FORMS_CREATE_EDIT_OWN,
  FORMS_EDIT_ALL,
  FORMS_SEND,
  FORMS_MANAGE_TEMPLATES,
  FORMS_ORIENTATION_FORMS_MANAGE,
  GALLERY_ACCESS,
  PROJECT_BANK_DELETE,
  PROJECT_BANK_TILAAJAVASTUU_DOCUMENTS_VIEW,
  PROJECT_BANK_TILAAJAVASTUU_DOCUMENTS_MANAGE,
  PROJECT_CREATE,
  PROJECT_EDIT,
  EXPENSE_TRACKING_ACCESS,
  VIEW_SUBPROJECTS,
  RECORDS_SEND_TO_APPROVAL,
  PROJECT_APPROVALS_MANAGE,
  PROJECT_TIMELINES_EDIT,
  PROJECT_TIMELINES_VIEW,
  ORIENTATION_QUIZ_MANAGE,
  COMPETENCIES_VIEW_OTHER_USER_COMPETENCIES,
];
