export const GENERATE_REPORT = 'generate_report';
export const VIEW_OTHER_RESOURCES = 'view_other_resources';

export const EDIT_OTHER_USERS_RECORDS = 'edit_other_users_records';
export const VIEW_OTHER_USERS_RECORDS = 'view_other_users_records';
export const RECORDS_EDIT_OWN_RECORDS = 'records_edit_own_records';
export const RECORDS_APPROVE = 'records_approve';

export const EDIT_PERMISSIONS_OF_PROJECT = 'edit_permissions_of_project';
export const VIEW_OTHERS_FILE_PERMISSIONS = 'view_others_file_permissions';
export const BULLETIN_BOARD_ACCESS = 'bulletin_board_access';
export const BULLETIN_CREATE_EDIT_OWN = 'bulletin_board_create_edit_own';
export const BULLETIN_BOARD_EDIT_ALL = 'bulletin_board_edit_all';
export const BULLETIN_BOARD_SEND = 'bulletin_board_send_bulletin';
export const FORMS_ACCESS = 'forms_access';
export const FORMS_CREATE_EDIT_OWN = 'forms_create_edit_own';
export const FORMS_EDIT_ALL = 'forms_edit_all';
export const FORMS_SEND = 'forms_send';
export const FORMS_MANAGE_TEMPLATES = 'forms_manage_templates';
export const FORMS_DELETE = 'forms_delete';
export const FORMS_ORIENTATION_FORMS_MANAGE = 'forms_orientation_forms_manage';
export const GALLERY_ACCESS = 'gallery_access';
export const GALLERY_VIEW_OTHER_USERS_IMAGES =
  'gallery_view_other_users_images';
export const GALLERY_EDIT_OTHER_USERS_IMAGES =
  'gallery_edit_other_users_images';
export const EXPENSE_TRACKING_ACCESS = 'expense_tracking_access';
export const PROJECT_ID_CARD_EDIT = 'project_id_card_edit';
export const VIEW_SUBPROJECTS = 'view_subprojects'; //display subprojects in projects list even if user has not joined them.
export const RECORDS_SEND_TO_APPROVAL = 'records_send_to_approval';
export const PROJECT_APPROVALS_MANAGE = 'project_approvals_manage'; // fix
export const RECORDS_CHECK_BILLED_TIMES = 'records_check_billed_times';
export const RECORDS_CHECK_CLOCKED_TIMES = 'records_check_clocked_times';
export const PROJECT_EDIT = 'project_edit';
export const PROJECT_TIMELINES_EDIT = 'project_timelines_edit';
export const PROJECT_TIMELINES_VIEW = 'project_timelines_view';
export const PROJECT_BANK_DELETE = 'project_bank_delete';
export const PROJECT_BANK_TILAAJAVASTUU_DOCUMENTS_VIEW =
  'project_bank_tilaajavastuu_documents_view';
export const PROJECT_BANK_TILAAJAVASTUU_DOCUMENTS_MANAGE =
  'project_bank_tilaajavastuu_documents_manage';
export const PROJECT_CREATE = 'project_create';
export const ORIENTATION_QUIZ_MANAGE = 'orientation_quiz_manage';
export const COMPETENCIES_VIEW_OTHER_USER_COMPETENCIES =
  'competencies_view_other_user_competencies';

//below permissions currently only used in frontend logic
export const PROJECT_BANK_ACCESS = 'project_bank_access';
export const PROJECT_TAX_AUTHORITY_NOTICE_CREATE =
  'project_tax_authority_notice_create';
export const PROJECT_BANK_VIEW_OTHER_USERS_DATA =
  'project_bank_view_other_users_data';
export const UPLOAD_PICTURE = 'upload_picture';
export const UPLOAD_FILE = 'upload_file';

export const PROJECT_GENERATE_REPORT = 'project_generate_report';
export const PROJECT_INVITE = 'project_invite';
export const WORK_CALENDAR_ACCESS = 'work_calendar_access';
export const EMPLOYEE_FEATURES_ACCESS = 'employee_features_access';
// end of permissions used only in frontend logic
