import axios from 'axios';
import { createSignedRequest } from 'utils/createSignedRequest';
import Config from 'Config';

export const patchTilaajavastuuDocumentAsDeleted = async (
  projectId,
  businessId
) => {
  const effectiveBusinessId = businessId.split('#')[0];

  const uuid = businessId.split('#')[1];
  const apiPath = `project-bank/${projectId}/delete-tilaajavastuu-document/${effectiveBusinessId}/${uuid}`;

  const request = {
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    // url: `http://localhost:3001/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'PATCH',
    region: Config.AWS_REGION,
    service: 'execute-api',
    body: JSON.stringify({}),
    data: {},
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const signedRequest = await createSignedRequest(request);
  const response = await axios(signedRequest);

  return response.data;
};
