import { createSelector } from '@reduxjs/toolkit';
import { COMPETENCY_CARD_TYPES } from 'constants/competency-card-types';
import i18n from 'i18next';
import { groupBy, orderBy, uniqBy } from 'lodash-es';
import { selectUserAdminBusinessIds } from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';
import { selectUserEmployers } from 'store/projects/selectors/userProjects.selectors';
import { selectAllResources } from 'store/resources/resourceSliceSelectors';
import { arrayToMap } from 'utils/arrayToMap';

const selectCardsState = (state) => state.cards;

export const selectCardsEntities = createSelector(
  [selectCardsState],
  (state) => state.cards.entities
);

export const selectCardsArray = createSelector([selectCardsEntities], (cards) =>
  Object.values(cards)
);

export const selectCardsArrayWithoutDeleted = createSelector(
  [selectCardsArray],
  (cards) => cards.filter((card) => !card.deletedAt)
);

export const selectValidCardsArrayWithFilledName = createSelector(
  [selectCardsArrayWithoutDeleted],
  (cards) =>
    cards.map((card) => ({
      ...card,
      name:
        card.type === 'CUSTOM_BUSINESS_COMPETENCY'
          ? card.name
          : i18n.t(
              `cards-and-competencies.${COMPETENCY_CARD_TYPES[card.type].name}`
            ),
    }))
);

export const selectValidCardsEntitiesWithFilledName = createSelector(
  [selectValidCardsArrayWithFilledName],
  (cards) => arrayToMap(cards)
);

export const selectUserIdForCardDialog = createSelector(
  [selectCardsState],
  (state) => state.userIdForCardDialog
);

export const selectIsCardDialogOpen = createSelector(
  [selectCardsState],
  (state) => state.isCardDialogOpen
);

export const selectIsAddCardDialogOpen = createSelector(
  [selectCardsState],
  (state) => state.isAddCardDialogOpen
);

export const selectIsAddCardForOtherUserDialogOpen = createSelector(
  [selectCardsState],
  (state) => state.isAddCardForOtherUserDialogOpen
);

export const selectUserIdToAddCardFor = createSelector(
  [selectCardsState],
  (state) => state.userIdToAddCardFor
);

export const selectIsCardsLoading = createSelector(
  [selectCardsState],
  (state) => state.loading
);

export const selectIsAddingCard = createSelector(
  [selectCardsState],
  (state) => state.isAddingCard
);

export const selectExpiringEmployeeCards = createSelector(
  [selectCardsState],
  (state) => state.expiringEmployeeCards.entities
);

export const selectExpiringEmployeeCardsArray = createSelector(
  [selectExpiringEmployeeCards],
  (cards) => Object.values(cards)
);

export const selectCardIdToDelete = createSelector(
  [selectCardsState],
  (state) => state.cardIdToDelete
);

export const selectIsDeletingCard = createSelector(
  [selectCardsState],
  (state) => state.isDeletingCard
);

export const selectIsDeleteCardDialogOpen = createSelector(
  [selectCardsState],
  (state) => state.isDeleteCardDialogOpen
);

export const selectSelectedCardTypeToAdd = createSelector(
  [selectCardsState],
  (state) => state.cardTypeToAdd
);

export const selectIsAddNewCompetencyTypeDialogOpen = createSelector(
  [selectCardsState],
  (state) => state.isAddNewCompetencyTypeDialogOpen
);

export const selectIsAddingNewCompetencyType = createSelector(
  [selectCardsState],
  (state) => state.isAddingNewCompetencyType
);

export const selectSelectedCardToDelete = createSelector(
  [selectValidCardsEntitiesWithFilledName, selectCardIdToDelete],
  (cards, selectedCardId) => cards[selectedCardId]
);

export const selectIsManageAddedCompetenciesDialogOpen = createSelector(
  [selectCardsState],
  (state) => state.isManageAddedCompetenciesDialogOpen
);

export const selectCustomBusinessCompetencyTypesEntities = createSelector(
  [selectCardsState],
  (state) => state.competencyTypes.entities
);

export const selectCustomBusinessCompetencyTypesArray = createSelector(
  [selectCustomBusinessCompetencyTypesEntities],
  (entities) => Object.values(entities)
);

export const selectUniqueCustomBusinessCompetencyTypes = createSelector(
  [selectCustomBusinessCompetencyTypesArray],
  (competencies) => {
    const uniqueCompetencies = uniqBy(competencies, 'name');

    const sortedCompetencies = orderBy(
      uniqueCompetencies,
      [(competency) => competency.name.toLowerCase()],
      ['asc']
    );

    return sortedCompetencies;
  }
);

export const selectManageableBusinessCompetencyTypes = createSelector(
  [
    selectCustomBusinessCompetencyTypesArray,
    selectUserAdminBusinessIds,
    selectUserEmployers,
  ],
  (competencies, userAdminBusinessIds, employerProjects) => {
    const ownBusinessCompetencies = competencies.filter((competency) =>
      userAdminBusinessIds.includes(competency.businessId)
    );

    const employerGroupedCompetencies = groupBy(
      ownBusinessCompetencies,
      'businessId'
    );

    const competenciesWithEmployerData = Object.entries(
      employerGroupedCompetencies
    ).map(([key, value]) => {
      const employerProject = employerProjects.find(
        (project) => project.business_id === key
      );

      const sortedCompetencies = orderBy(
        value,
        [(competency) => competency.name.toLowerCase()],
        ['asc']
      );

      return {
        competencies: sortedCompetencies,
        employerName: employerProject?.name,
      };
    });

    return competenciesWithEmployerData;
  }
);

export const selectUserNameToAddCardFor = createSelector(
  [selectUserIdToAddCardFor, selectAllResources],
  (selectedUserId, resources) => {
    const userResource = resources.find(
      (resource) => resource.user === selectedUserId
    );

    if (userResource) {
      const { firstName, lastName } = userResource;
      return `${firstName} ${lastName}`;
    } else {
      return undefined;
    }
  }
);
