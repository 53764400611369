import { createAction } from '@reduxjs/toolkit';

export const closePermissionsView = createAction(
  'permissions/closePermissionsView'
);

export const searchPermissionsViewUsers = createAction(
  'permissions/searchPermissionsViewUsers'
);

export const modifyProjectRole = createAction('permissions/modifyProjectRole');

export const modifyUserRole = createAction('permissions/modifyUserRole');

export const modifySingleUserPermission = createAction(
  'permissions/modifySingleUserPermission'
);

export const modifyMultipleUserRoles = createAction(
  'permissions/modifyMultipleUserRoles'
);

export const closeProjectRoles = createAction('permissions/closeProjectRoles');

export const closeProjectUsers = createAction('permissions/closeProjectUsers');

export const setUsersGroupedBy = createAction('permissions/setUsersGroupedBy');

export const selectUserToAssignRole = createAction(
  'permissions/selectUserToAssignRole'
);

export const selectMultipleUsersToAssignRole = createAction(
  'permissions/selectMultipleUsersToAssignRole'
);

export const dismissSelectedUsersToAssignRole = createAction(
  'permissions/dismissSelectedUsersToAssignRole'
);

export const dismissMultipleUsersToAssignRole = createAction(
  'permissions/dismissMultipleUsersToAssignRole'
);
