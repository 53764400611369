import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { generateReportFromRecords } from 'scenes/WorkDemo/utils/report-generation/generateReportFromRecords';
import { selectAssignmentsArray } from 'store/assignments/selectors/assignment.selectors';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';
import { selectProjectUsersArray } from 'store/projectUsers/selectors/projectUsers.selectors';
import {
  selectProjectsDataResourcesOfWorkCalendarRecords,
  selectWorkCalendarFilteredRecords,
  selectWorkCalendarProjectsArray,
} from '../../selectors/workSelectorsDemo';
import { selectIsRecordBasedReductionsActive } from 'store/reports/selectors/reports.selectors';

export const generateReportFromWorkCalendarData = createAsyncThunk(
  'workCalendar/generateReportFromWorkCalendarData',
  async (dialogSettings, { getState }) => {
    const state = getState();

    const selectedProjectId = selectSelectedProjectId(state);

    const allWorkCalendarFilteredRecords =
      selectWorkCalendarFilteredRecords(state);

    const resources = selectProjectsDataResourcesOfWorkCalendarRecords(
      getState()
    );

    const users = selectProjectUsersArray(getState());

    const assignments = selectAssignmentsArray(getState());

    const projects = selectWorkCalendarProjectsArray(getState());

    const utcOffset = moment.parseZone(moment().format()).format('Z');

    const isRecordBasedReductionsActive = selectIsRecordBasedReductionsActive(
      getState()
    );

    const {
      selectedReportType,
      workStartTime,
      workEndTime,
      fromDate,
      toDate,
      language,
      filetype,
      workHourType,
      isDetailedReportSelected,
      paidCoffeeBreakMinutes,
    } = dialogSettings;

    const generationParams = {
      fromDate,
      toDate,
      selectedReportType,
      workStartTime,
      workEndTime,
      language,
      filetype,
      utcOffset,
      workHourType,
      isDetailedReportSelected,
      isRecordBasedReductionsActive,
      paidCoffeeBreakMinutes,
    };

    const report = await generateReportFromRecords(
      allWorkCalendarFilteredRecords,
      resources,
      assignments,
      users,
      generationParams,
      projects,
      selectedProjectId
    );

    return report;
  }
);
