import { createSelector } from '@reduxjs/toolkit';
import { RECORD_TYPE } from 'LixaniAPI/enums';
import dayjs from 'dayjs';
import { orderBy, sortBy, uniq, uniqBy } from 'lodash-es';
import moment from 'moment';
import {
  selectResourceDefaultPermissions,
  selectUserAdminBusinessIds,
  selectUserAdminPermissions,
  selectUserProjectPermissions,
} from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';
import { selectPersonalWorkAndBreakRecordsFromToday } from 'scenes/WorkDemo/store/selectors/personalWorkCalendar.selectors';
import returnProjectIdAndItsChildProjectIds from 'scenes/WorkDemo/utils/returnProjectIdAndItsChildProjectIds';
import { selectAssignments } from 'store/assignments/selectors/assignment.selectors';
import {
  selectProjects,
  selectProjectsArray,
  selectSelectedProject,
  selectSelectedProjectId,
} from 'store/projects/selectors/projectsSelectors';
import { checkPermissionsToApproveRecord } from 'store/projects/utils/checkPermissionToApproveRecord';
import { checkPermissionsToCheckRecordBilledInTimes } from 'store/projects/utils/checkPermissionsToCheckRecordBilledInTimes';
import { checkPermissionsToCheckRecordClockedInTimes } from 'store/projects/utils/checkPermissionsToCheckRecordClockedInTimes';
import { checkPermissionsToEditRecord } from 'store/projects/utils/checkPermissionsToEditRecord';
import { selectResourceEntities } from 'store/resources/resourceSliceSelectors';
import {
  selectLoggedInUserResourcesArray,
  selectResourcesEntitiesWithJoinedData,
} from 'store/resources/selectors/resources.selectors';
import { selectCurrentUser } from 'store/selectors/root.selectors';
import { formatRecordChanges } from '../utils/formatRecordChanges';
import {
  selectRecords,
  selectRecordsArray,
  selectRecordsState,
  selectSelectedRecordId,
  selectWorkCalendarRecords,
  selectWorkCalendarRecordsArray,
} from './records-base.selectors';

export const selectRecordsArrayWithJoinedData = createSelector(
  [selectRecordsArray, selectProjects, selectResourcesEntitiesWithJoinedData],
  (records, projects, resources) =>
    records.map((record) => ({
      ...record,
      project: projects[record.project]
        ? projects[record.project]
        : { id: record.project },
      resource: resources[record.resource]
        ? resources[record.resource]
        : { id: record.resource },
    }))
);

export const selectIsRecordHistoryLoading = createSelector(
  [selectRecordsState],
  (state) => state.historyLoading
);

export const selectIsRecordsLoading = createSelector(
  [selectRecordsState],
  (state) => state.loading
);

export const selectSelectedRecordsIds = createSelector(
  [selectRecordsState],
  (state) => state.selectedRecords.ids
);

export const selectSelectedRecords = createSelector(
  [selectRecordsState],
  (state) => state.selectedRecords.entities
);

export const selectSelectedRecordsArray = createSelector(
  [selectSelectedRecords],
  (records) =>
    Object.values(records).map((record) => ({
      ...record,
    }))
);

export const selectIsClockOutPending = createSelector(
  [selectRecordsState],
  (state) => state.clockOutPending
);

export const selectIsClockedInRecordsLoading = createSelector(
  [selectRecordsState],
  (state) => state.loadingClockedInRecords
);

export const selectIsProjectRecordsFromTodayLoaded = createSelector(
  [selectRecordsState],
  (state) => state.isProjectRecordsFromTodayLoaded
);

export const selectIsEditingRecord = createSelector(
  [selectRecordsState],
  (state) => state.isEditingRecord
);

export const selectIsDialogResourcesLoading = createSelector(
  [selectRecordsState],
  (state) => state.dialogResourcesLoading
);

export const selectSelectedRecord = createSelector(
  [selectWorkCalendarRecords, selectRecords, selectSelectedRecordId],
  (workCalendarRecords, generalRecords, selectedRecordId) => {
    const recordFoundFromGeneralRecords =
      generalRecords && generalRecords[selectedRecordId]
        ? generalRecords[selectedRecordId]
        : undefined;

    if (recordFoundFromGeneralRecords) return recordFoundFromGeneralRecords;

    const recordFoundFromWorkCalendarRecords =
      workCalendarRecords && workCalendarRecords[selectedRecordId]
        ? workCalendarRecords[selectedRecordId]
        : undefined;

    return recordFoundFromWorkCalendarRecords
      ? recordFoundFromWorkCalendarRecords
      : {};
  }
);

export const selectSelectedRecordProject = createSelector(
  [selectProjects, selectSelectedRecord],
  (projects, record) => {
    return projects[record.project];
  }
);

export const selectSelectedRecordApproveStatus = createSelector(
  [selectSelectedRecord],
  (record) => (record && record.approved ? record.approved : false)
);

export const selectIsSelectedRecordPartOfApprovalGroup = createSelector(
  [selectSelectedRecord],
  (record) => (record && record.groupCode ? true : false)
);

export const selectSelectedRecordCheckStatus = createSelector(
  [selectSelectedRecord],
  (record) =>
    record &&
    record.billedInAtChecked &&
    record.billedOutAtChecked &&
    record.clockedInAtChecked &&
    record.clockedOutAtChecked
      ? true
      : false
);

export const selectSelectedRecordCheckTimes = createSelector(
  [selectSelectedRecord],
  (record) => {
    return {
      billedInAtChecked: record.billedInAtChecked
        ? record.billedInAtChecked
        : false,
      billedOutAtChecked: record.billedOutAtChecked
        ? record.billedOutAtChecked
        : false,
      clockedInAtChecked: record.clockedInAtChecked
        ? record.clockedInAtChecked
        : false,
      clockedOutAtChecked: record.clockedOutAtChecked
        ? record.clockedOutAtChecked
        : false,
    };
  }
);

export const selectSelectedRecordChanges = createSelector(
  [selectSelectedRecord],
  (record) => (record && record.changes ? record.changes : undefined)
);

export const selectRecordStatusChangePending = createSelector(
  [selectRecordsState],
  (state) => state.recordStatusChangePending
);

export const selectRecordEditPending = createSelector(
  [selectRecordsState],
  (state) => (state.editRecordPending ? state.editRecordPending : false)
);

export const selectPersonalRecordsWithJoinedData = createSelector(
  [
    selectRecordsArrayWithJoinedData,
    selectLoggedInUserResourcesArray,
    selectCurrentUser,
  ],
  (records, currentUserResources, user) => {
    const currentUserResourceIds = currentUserResources.map(
      (resource) => resource.id
    );
    return records.filter(
      (record) =>
        (currentUserResourceIds.includes(record.resource.id) ||
          // used in optimistic reponse logic, until the record resource is returned on successful api response. This way record can be in most cases directly shown as clocked in to user.
          (!record.resource.id && record.mirrorUser === user.id)) &&
        !record.deletedAt
    );
  }
);

export const selectPersonalRecordProjectsFromToday = createSelector(
  [selectPersonalWorkAndBreakRecordsFromToday, selectProjectsArray],
  (records, projects) => {
    const recordProjectIds = uniq(
      sortBy(records, 'clockInAt').map((record) => record.project)
    );

    const projectsOfRecords = projects.filter((project) =>
      recordProjectIds.includes(project.id)
    );

    // getting projects in right order
    const projectsOfRecordsSorted = recordProjectIds.map((id) => {
      return projectsOfRecords.find((project) => project.id === id);
    });

    return projectsOfRecordsSorted;
  }
);

export const selectPersonalClockedInRecords = createSelector(
  [selectPersonalRecordsWithJoinedData],
  (records) => {
    const clockedInRecords = records.filter(
      (record) =>
        record.status === 'IN' &&
        (record.type === RECORD_TYPE.WORK ||
          record.type === RECORD_TYPE.PROJECTWORK)
    );
    return clockedInRecords ? clockedInRecords : [];
  }
);

export const selectPersonalClockedInBreakRecords = createSelector(
  [selectPersonalRecordsWithJoinedData],
  (records) => {
    const clockedInBreakRecords = records.filter(
      (record) =>
        record.status === 'IN' && record.type.includes(RECORD_TYPE.BREAK)
    );

    return clockedInBreakRecords ? clockedInBreakRecords : [];
  }
);

const selectIsOwnerOfSelectedRecordProject = createSelector(
  [selectSelectedRecord, selectProjects, selectCurrentUser],
  (selectedRecord, projects, currentUser) => {
    const recordProject =
      projects && selectedRecord ? projects[selectedRecord.project] : {};

    const isOwnerOfRecordProject = recordProject
      ? recordProject.mirrorUser === currentUser.id
      : false;

    return isOwnerOfRecordProject;
  }
);

export const selectIsAdminOfSelectedRecordProject = createSelector(
  [selectSelectedRecord, selectUserAdminBusinessIds, selectProjects],
  (selectedRecord, adminBusinessIds, projects) => {
    const selectedRecordProject = selectedRecord
      ? projects[selectedRecord.project]
      : {};

    const employerBisnesId =
      selectedRecordProject && selectedRecordProject.business_id
        ? selectedRecordProject.business_id
        : selectedRecordProject && selectedRecordProject.masterParent
        ? projects[selectedRecordProject.masterParent].business_id
        : undefined;

    const isAdminOfRecordProject = selectedRecordProject
      ? adminBusinessIds.includes(employerBisnesId)
      : false;

    return isAdminOfRecordProject;
  }
);

export const selectIsAdminOfSelectedRecordResource = createSelector(
  [selectSelectedRecord, selectResourceEntities, selectUserAdminBusinessIds],
  (selectedRecord, resources, adminBusinessIds) => {
    const selectedRecordResource = selectedRecord
      ? resources[selectedRecord.resource]
      : {};

    const employerBusinessId =
      selectedRecordResource && selectedRecordResource.employerBusinessId
        ? selectedRecordResource.employerBusinessId
        : undefined;

    const isAdminOfRecordResource = selectedRecordResource
      ? adminBusinessIds.includes(employerBusinessId)
      : false;

    return isAdminOfRecordResource;
  }
);

export const selectSelectedProjectAndChildProjectRecords = createSelector(
  [
    selectWorkCalendarRecordsArray,
    selectSelectedProjectId,
    selectSelectedProject,
    selectProjectsArray,
  ],
  (records, selectedProjectId, selectedProject, projects) => {
    let projectIdsToReturnRecordsFrom = [];

    if (selectedProject && selectedProject.businessTypeEnum === 'EMPLOYER') {
      const allProjectIdsInBusiness = projects
        .filter(
          (project) => project.business_id === selectedProject.business_id
        )
        .map((project) => project.id);

      projectIdsToReturnRecordsFrom = allProjectIdsInBusiness;
    } else {
      const projectAndChildProjectIds = returnProjectIdAndItsChildProjectIds(
        [selectedProjectId],
        projects
      );
      projectIdsToReturnRecordsFrom = projectAndChildProjectIds;
    }

    const recordsInSelectedProjectsAndChildProjects = records.filter((record) =>
      projectIdsToReturnRecordsFrom.includes(record.project)
    );

    return recordsInSelectedProjectsAndChildProjects;
  }
);

export const selectSelectedProjectInRecord = createSelector(
  [selectPersonalClockedInRecords, selectSelectedProjectId],
  (clockedInRecords, selectedProjectId) =>
    clockedInRecords.filter(
      (record) => record.project.id === selectedProjectId
    )[0]
);

export const selectSelectedProjectClockedInBreakRecords = createSelector(
  [selectPersonalClockedInBreakRecords, selectSelectedProjectId],
  (clockedInBreakRecords, selectedProjectId) =>
    clockedInBreakRecords.filter(
      (record) => record.project.id === selectedProjectId
    )
);

export const isPermissionsToEditSelectedRecord = createSelector(
  [
    selectSelectedRecord,
    selectUserProjectPermissions,
    selectProjects,
    selectResourceDefaultPermissions,
    selectCurrentUser,
    selectIsOwnerOfSelectedRecordProject,
    selectIsAdminOfSelectedRecordResource,
    selectIsAdminOfSelectedRecordProject,
  ],
  (
    selectedRecord,
    userProjectPermissions,
    projects,
    resourceDefaultPermissions,
    currentUser,
    isOwnerOfRecordProject,
    isBusinessAdminOfResource,
    IsAdminOfSelectedRecordProject
  ) => {
    return checkPermissionsToEditRecord(
      selectedRecord,
      userProjectPermissions,
      projects,
      resourceDefaultPermissions,
      currentUser,
      isOwnerOfRecordProject,
      isBusinessAdminOfResource,
      IsAdminOfSelectedRecordProject
    );
  }
);

export const isPermissionToApproveSelectedRecord = createSelector(
  [
    selectSelectedRecord,
    selectUserProjectPermissions,
    selectProjects,
    selectResourceDefaultPermissions,
    selectIsAdminOfSelectedRecordProject,
  ],
  (
    selectedRecord,
    userProjectPermissions,
    projects,
    resourceDefaultPermissions,
    IsAdminOfSelectedRecordProject
  ) => {
    return checkPermissionsToApproveRecord(
      selectedRecord,
      userProjectPermissions,
      projects,
      resourceDefaultPermissions,
      IsAdminOfSelectedRecordProject
    );
  }
);

export const isPermissionToCheckSelectedRecordClockedTimes = createSelector(
  [
    selectSelectedRecord,
    selectUserProjectPermissions,
    selectProjects,
    selectResourceDefaultPermissions,
    selectIsAdminOfSelectedRecordProject,
    selectIsAdminOfSelectedRecordResource,
  ],
  (
    selectedRecord,
    userProjectPermissions,
    projects,
    resourceDefaultPermissions,
    IsAdminOfSelectedRecordProject,
    IsAdminOfSelectedRecordResource
  ) => {
    return checkPermissionsToCheckRecordClockedInTimes(
      selectedRecord,
      userProjectPermissions,
      projects,
      resourceDefaultPermissions,
      IsAdminOfSelectedRecordProject,
      IsAdminOfSelectedRecordResource
    );
  }
);
export const isPermissionToCheckSelectedRecordBilledTimes = createSelector(
  [
    selectSelectedRecord,
    selectUserProjectPermissions,
    selectProjects,
    selectResourceDefaultPermissions,
    selectIsAdminOfSelectedRecordProject,
    selectIsAdminOfSelectedRecordResource,
  ],
  (
    selectedRecord,
    userProjectPermissions,
    projects,
    resourceDefaultPermissions,
    IsAdminOfSelectedRecordProject,
    IsAdminOfSelectedRecordResource
  ) => {
    return checkPermissionsToCheckRecordBilledInTimes(
      selectedRecord,
      userProjectPermissions,
      projects,
      resourceDefaultPermissions,
      IsAdminOfSelectedRecordProject,
      IsAdminOfSelectedRecordResource
    );
  }
);

export const selectSelectedProjectEmployeeRecordsClockedInToday =
  createSelector(
    [
      selectRecordsArrayWithJoinedData,
      selectSelectedProject,
      selectProjectsArray,
    ],
    (records, selectedProject, projects) => {
      const fromDate = dayjs().startOf('day').utcOffset(0).format();
      const toDate = dayjs().endOf('day').utcOffset(0).format();

      let recordsClockedInToday = [];

      if (selectedProject.businessTypeEnum === 'EMPLOYER') {
        const employerProjectAndChildProjectIds =
          returnProjectIdAndItsChildProjectIds([selectedProject.id], projects);

        recordsClockedInToday = records.filter(
          (record) =>
            employerProjectAndChildProjectIds.includes(record.project.id) &&
            record.clockInAt >= fromDate &&
            record.clockInAt <= toDate
        );
      } else {
        recordsClockedInToday = records.filter(
          (record) =>
            selectedProject.id === record.project.id &&
            record.clockInAt >= fromDate &&
            record.clockInAt <= toDate
        );
      }

      return recordsClockedInToday;
    }
  );

export const selectCreateRecordDialogOpen = createSelector(
  [selectRecordsState],
  (state) => state.createRecordDialogOpen
);

export const selectClockOutRecordDialogOpen = createSelector(
  [selectRecordsState],
  (state) => state.clockOutRecordDialogOpen
);

export const selectCreateRecordDialogData = createSelector(
  [selectRecordsState],
  (state) => state.createRecordDialogData
);

export const selectCreateRecordDialogDataProject = createSelector(
  [selectCreateRecordDialogData],
  (dialogData) =>
    dialogData && dialogData.project ? dialogData.project : undefined
);

export const selectEditRecordDialogOpen = createSelector(
  [selectRecordsState],
  (state) => state.editRecordDialogOpen
);

export const selectEditRecordDialogData = createSelector(
  [selectRecordsState],
  (state) => state.editRecordDialogData
);

export const selectIsAdminOfEditRecordResourceEmployer = createSelector(
  [
    selectEditRecordDialogData,
    selectResourceEntities,
    selectUserAdminPermissions,
    selectProjects,
  ],
  (editRecordDialogData, resources, adminPermissions, projects) => {
    const recordResource = resources[editRecordDialogData.resource];

    if (recordResource && recordResource.employer) {
      const resourceEmployerProject = projects[recordResource.employer];
      console.log(resourceEmployerProject);

      return adminPermissions[resourceEmployerProject] &&
        adminPermissions[resourceEmployerProject.business_id]
        ? true
        : false;
    }

    return false;
  }
);

export const selectIsAdminOfRecordEditProject = createSelector(
  [selectEditRecordDialogData, selectUserAdminPermissions, selectProjects],
  (editRecordDialogData, adminPermissions, projects) => {
    const recordProject = projects[editRecordDialogData.project];

    if (recordProject && recordProject.business_id) {
      return adminPermissions[recordProject.business_id] ? true : false;
    }
    return false;
  }
);

export const selectIsAdminOfCreatingRecordProject = createSelector(
  [
    selectCreateRecordDialogDataProject,
    selectUserAdminBusinessIds,
    selectProjects,
  ],
  (createRecordDialogDataProject, adminBusinessIds, projects) => {
    const selectedRecordProject = createRecordDialogDataProject
      ? projects[createRecordDialogDataProject]
      : {};

    const employerBisnesId =
      selectedRecordProject && selectedRecordProject.business_id
        ? selectedRecordProject.business_id
        : selectedRecordProject && selectedRecordProject.masterParent
        ? projects[selectedRecordProject.masterParent].business_id
        : undefined;

    const isAdminOfRecordProject = selectedRecordProject
      ? adminBusinessIds.includes(employerBisnesId)
      : false;

    return isAdminOfRecordProject;
  }
);

export const selectEditDialogIsRecordAttributeChanges = createSelector(
  [selectSelectedRecord, selectEditRecordDialogData],
  (record, dialogData) => {
    // Get the keys that are common to both objects. DialogData only has editable attributes.
    const commonAttributes = Object.keys(record).filter((key) =>
      Object.keys(dialogData).includes(key)
    );

    // Compare all attributes in common.
    for (const key of commonAttributes) {
      if (record[key] !== dialogData[key]) {
        return true;
      }
    }

    return false;
  }
);

export const selectSelectedRecordChangesInObjectFormatWithfilledData =
  createSelector(
    [
      selectSelectedRecordChanges,
      selectAssignments,
      selectProjects,
      selectResourcesEntitiesWithJoinedData,
      selectIsAdminOfSelectedRecordProject,
    ],
    (changes, assignments, projects, resources, isAdmin) => {
      // this is for the old record history
      if (changes) {
        const changesInObjectFormat = formatRecordChanges(
          changes,
          assignments,
          projects,
          resources,
          isAdmin
        );

        return changesInObjectFormat;
      } else return [];
    }
  );

export const selectSelectedRecordChangeHistory = createSelector(
  [selectRecordsState],
  (state) => state.selectedRecordHistory
);

export const selectSelectedRecordChangeHistoryWithFilledData = createSelector(
  [
    selectSelectedRecordChangeHistory,
    selectAssignments,
    selectProjects,
    selectResourcesEntitiesWithJoinedData,
    selectIsAdminOfSelectedRecordProject,
  ],
  (recordChangeHistory, assignments, projects, resources, isAdmin) => {
    const changeHistoryWithFilledData = recordChangeHistory.map(
      (historyObject) => {
        let attributes = { ...historyObject.attributes }; // Create a new object
        if (!isAdmin) {
          // this should be done in API
          delete attributes['billedClockInAt'];
          delete attributes['billedClockOutAt'];
        }

        Object.keys(attributes).map((attributeKey) => {
          const attributeValue = attributes[attributeKey];

          if (attributeKey === 'project') {
            attributes[attributeKey] = {
              old: projects[attributeValue.old]
                ? `${projects[attributeValue.old].name} (${
                    projects[attributeValue.old].projectKey
                  })`
                : attributeValue.old,
              new: projects[attributeValue.new]
                ? `${projects[attributeValue.new].name} (${
                    projects[attributeValue.new].projectKey
                  })`
                : attributeValue.new,
            };
          }
          if (attributeKey === 'assignment') {
            attributes[attributeKey] = {
              old: assignments[attributeValue.old]
                ? assignments[attributeValue.old].name
                : attributeValue.old,
              new: assignments[attributeValue.new]
                ? assignments[attributeValue.new].name
                : attributeValue.new,
            };
          }

          if (attributeKey === 'resource') {
            const oldResource = resources[attributeValue.old];
            const newResource = resources[attributeValue.new];

            const oldResouceEmployerName =
              oldResource && oldResource.employer && oldResource.employer.name
                ? oldResource.employer.name
                : 'MyLixani';
            const newResourceEmployerName =
              newResource && newResource.employer && newResource.employer.name
                ? newResource.employer.name
                : 'MyLixani';

            attributes[attributeKey] = {
              old: oldResource
                ? `${oldResouceEmployerName} / ${oldResource.firstName} ${oldResource.lastName}`
                : attributeValue.old,
              new: newResource
                ? `${newResourceEmployerName} / ${newResource.firstName} ${newResource.lastName}`
                : attributeValue.new,
            };
          }
          if (attributeKey === 'approved') {
            attributes[attributeKey] = {
              old: attributeValue.old ? 'approved' : 'unapproved',
              new: attributeValue.new ? 'approved' : 'unapproved',
            };
          }
          if (attributeKey.includes('Checked')) {
            attributes[attributeKey] = {
              old: attributeValue.old ? 'checked' : 'unchecked',
              new: attributeValue.new ? 'checked' : 'unchecked',
            };
          }
          if (
            attributeKey === 'clockInAt' ||
            attributeKey === 'clockOutAt' ||
            attributeKey === 'billedClockInAt' ||
            attributeKey === 'billedClockOutAt'
          ) {
            const dateFormat = 'DD.MM.yyyy HH.mm';

            attributes[attributeKey] = {
              old: attributeValue.old
                ? moment(attributeValue.old).format(dateFormat)
                : '',
              new: attributeValue.new
                ? moment(attributeValue.new).format(dateFormat)
                : '',
            };
          }
        });

        return {
          ...historyObject,
          attributes: attributes,
        };
      }
    );

    return orderBy(changeHistoryWithFilledData, 'timestamp', 'asc');
  }
);

export const selectIsLocationPending = createSelector(
  [selectRecordsState],
  (state) => {
    return state.locationPending;
  }
);
export const selectLocationMustDialog = createSelector(
  [selectRecordsState],
  (state) => {
    return state.locationMustDialog;
  }
);
export const selectClockInPendingProjectIds = createSelector(
  [selectRecordsState],
  (state) => {
    return state.clockInPendingProjectIds.ids;
  }
);

export const selectSelectedRecordBreakRecordsEditAttributes = createSelector(
  [selectRecordsState],
  (state) => state.selectedRecordBreakRecordsEditAttributes.entities
);

export const selectSelectedRecordBreakRecordsEditAttributesArray =
  createSelector([selectRecordsState], (state) =>
    Object.values(state.selectedRecordBreakRecordsEditAttributes.entities)
  );

export const selectSelectedRecordBreaksWithEditAttributes = createSelector(
  [
    selectSelectedRecordId,
    selectRecordsArray,
    selectWorkCalendarRecordsArray,
    selectSelectedRecordBreakRecordsEditAttributes,
  ],
  (
    selectedRecordId,
    records,
    workCalendarRecords,
    breakRecordsEditAttributes
  ) => {
    const allRecords = [...records, ...workCalendarRecords];

    const breakRecords = uniqBy(
      allRecords.filter(
        (record) =>
          record.parentRecordId === selectedRecordId &&
          record.type.includes('BREAK')
      ),
      'id'
    )
      .map((breakRecord) => {
        const editedAttributes = breakRecordsEditAttributes[breakRecord.id]
          ? breakRecordsEditAttributes[breakRecord.id]
          : {};

        return { ...breakRecord, ...editedAttributes };
      })
      .filter((record) => !record.deletedAt);

    return sortBy(breakRecords, 'clockInAt');
  }
);

export const selectClockOutDialogAttributes = createSelector(
  [selectRecordsState],
  (state) => {
    return state.clockOutDialogAttributes;
  }
);
export const selectedClockOutRecordWithUpdatedAttributes = createSelector(
  [selectSelectedRecord, selectClockOutDialogAttributes],
  (record, updateAttributes) => {
    return { ...record, ...updateAttributes };
  }
);

export const selectSelectableRecordsIds = createSelector(
  [selectWorkCalendarRecordsArray, selectRecordsArray],
  (workCalendarRecords, records) => {
    const allRecords = [...workCalendarRecords, records];

    const selectableRecordTypes = [RECORD_TYPE.WORK, RECORD_TYPE.PROJECTWORK];

    const selectableRecordsIds = allRecords
      .filter((record) => selectableRecordTypes.includes(record.type))
      .map((record) => record.id);

    return selectableRecordsIds;
  }
);
