import { createSlice } from '@reduxjs/toolkit';
import {
  closeAssignRoleToSelectedUsersDialog,
  closeConfirmAdminModifyDialog,
  closeConfirmUserRoleChangesDialog,
  closeCreateNewRoleDialog,
  closeDeleteProjectRoleConfirmationDialog,
  closeEditSingleUserPermissionsDialog,
  closeManageBusinessAdminsDialog,
  closeProjectRoleChangeHistoryDialog,
  closeRemoveRoleFromUserDialog,
  openAssignRoleToSelectedUsersDialog,
  openConfirmAdminModifyDialog,
  openConfirmUserRoleChangesDialog,
  openCreateNewRoleDialog,
  openEditSingleUserPermissionsDialog,
  openManageBusinessAdminsDialog,
  openProjectRoleChangeHistoryDialog,
  openRemoveRoleFromUserDialog,
  setProjectRoleIdToDelete,
} from '../actions/permissons-dialog.actions';
import { createProjectRoleThunk } from '../thunks/roles/createProjectRole.thunk';
import { deleteProjectRole } from '../thunks/roles/deleteProjectRole.thunk';
import { assignAdminPermissionsForUser } from '../thunks/admins/assignAdminPermissionsForUser';
import { removeAdminStatusOfUser } from '../thunks/admins/removeAdminStatusOfUser.thunk';
import { saveUserRoleModifications } from '../thunks/roles/saveUserRoleModifications.thunk';
import { modifyMultipleUserRoles } from '../actions/permissions-view.actions';

export const permissionsDialogSlice = createSlice({
  name: 'permissionsDialog',
  initialState: {
    isRemoveRoleFromUserDialogOpen: false,
    isCreateNewRoleDialogOpen: false,
    isChangeHistoryDialogOpen: false,
    isEditSingleUserPermissionsDialogOpen: false,
    isConfirmAdminModifyDialogOpen: false,
    isManageBusinessAdminsDialogOpen: false,
    isConfirmUserRoleChangesDialogOpen: false,
    isAssignRoleToSelectedUsersDialogOpen: false,
    changeHistoryTargetItemId: '',
    projectRoleIdToDelete: '',
    removeRoleFromUserDialogData: {},
    editSingleUserPermissionsDialogData: {},
    confirmAdminModifyDialogData: {},
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(openRemoveRoleFromUserDialog, (state, action) => ({
        ...state,
        isRemoveRoleFromUserDialogOpen: true,
        removeRoleFromUserDialogData: action.payload,
      }))
      .addCase(closeRemoveRoleFromUserDialog, (state) => ({
        ...state,
        isRemoveRoleFromUserDialogOpen: false,
      }))
      .addCase(openCreateNewRoleDialog, (state, action) => {
        state.isCreateNewRoleDialogOpen = true;
      })
      .addCase(closeCreateNewRoleDialog, (state, action) => {
        state.isCreateNewRoleDialogOpen = false;
      })
      .addCase(createProjectRoleThunk.fulfilled, (state, action) => {
        state.isCreateNewRoleDialogOpen = false;
      })
      .addCase(openProjectRoleChangeHistoryDialog, (state, action) => {
        state.isChangeHistoryDialogOpen = true;
        state.changeHistoryTargetItemId = action.payload;
      })
      .addCase(closeProjectRoleChangeHistoryDialog, (state, action) => {
        state.isChangeHistoryDialogOpen = false;
        state.changeHistoryTargetItemId = '';
      })
      .addCase(setProjectRoleIdToDelete, (state, action) => {
        state.projectRoleIdToDelete = action.payload;
      })
      .addCase(closeDeleteProjectRoleConfirmationDialog, (state, action) => {
        state.projectRoleIdToDelete = '';
      })
      .addCase(deleteProjectRole.fulfilled, (state, action) => {
        state.projectRoleIdToDelete = '';
      })
      .addCase(openEditSingleUserPermissionsDialog, (state, action) => {
        state.isEditSingleUserPermissionsDialogOpen = true;
        state.editSingleUserPermissionsDialogData = action.payload;
      })
      .addCase(closeEditSingleUserPermissionsDialog, (state, action) => {
        state.isEditSingleUserPermissionsDialogOpen = false;
        state.editSingleUserPermissionsDialogData = {};
      })
      .addCase(openConfirmAdminModifyDialog, (state, action) => {
        state.isConfirmAdminModifyDialogOpen = true;
        state.confirmAdminModifyDialogData = action.payload;
      })
      .addCase(closeConfirmAdminModifyDialog, (state, action) => {
        state.isConfirmAdminModifyDialogOpen = false;
        state.confirmAdminModifyDialogData = {};
      })
      .addCase(assignAdminPermissionsForUser.fulfilled, (state, action) => {
        state.isConfirmAdminModifyDialogOpen = false;
        state.confirmAdminModifyDialogData = {};
      })
      .addCase(removeAdminStatusOfUser.fulfilled, (state, action) => {
        state.isConfirmAdminModifyDialogOpen = false;
        state.confirmAdminModifyDialogData = {};
      })
      .addCase(openManageBusinessAdminsDialog, (state, action) => {
        state.isManageBusinessAdminsDialogOpen = true;
      })
      .addCase(closeManageBusinessAdminsDialog, (state, action) => {
        state.isManageBusinessAdminsDialogOpen = false;
      })
      .addCase(openConfirmUserRoleChangesDialog, (state, action) => {
        state.isConfirmUserRoleChangesDialogOpen = true;
      })
      .addCase(closeConfirmUserRoleChangesDialog, (state, action) => {
        state.isConfirmUserRoleChangesDialogOpen = false;
      })
      .addCase(saveUserRoleModifications.fulfilled, (state, action) => {
        state.isConfirmUserRoleChangesDialogOpen = false;
      })
      .addCase(openAssignRoleToSelectedUsersDialog, (state, action) => {
        state.isAssignRoleToSelectedUsersDialogOpen = true;
      })
      .addCase(closeAssignRoleToSelectedUsersDialog, (state, action) => {
        state.isAssignRoleToSelectedUsersDialogOpen = false;
      })
      .addCase(modifyMultipleUserRoles, (state, action) => {
        state.isAssignRoleToSelectedUsersDialogOpen = false;
      });
  },
});
