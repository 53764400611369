import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBusinessCompetencyTypes } from '../services/getBusinessCompetencyTypes.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const loadBusinessCompetencyTypes = createAsyncThunk(
  'cards/loadBusinessCompetencyTypes',
  async (_, { getState }) => {
    // if currently inside some project when fetching, also fetch competencies from business of the project
    const selectedProjectId = selectSelectedProjectId(getState());

    const response = await getBusinessCompetencyTypes(selectedProjectId);

    return response;
  }
);
