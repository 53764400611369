import axios from 'axios';
import Config from 'Config';
import { createSignedRequest } from 'utils/createSignedRequest';

export const setSortMultipleInvoicesBySortRule = async (
  mainProjectId,
  invoiceSortRuleId,
  fromDate,
  toDate,
  sortFromDate,
  sortToDate,
  overrideSort
) => {
  const apiPath = `invoice/${mainProjectId}/invoice-sort-rules/${invoiceSortRuleId}/sort-multiple-invoices-by-sort-rule`;

  const body = {
    fromDate,
    toDate,
    sortFromDate,
    sortToDate,
    overrideSort,
  };

  const request = {
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    // url: `http://localhost:3001/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    data: body,
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    region: Config.AWS_REGION,
    service: 'execute-api',
  };
  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest);
  return response.data;
};
