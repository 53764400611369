import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { RecordsTimelineProjects } from './RecordTimelineProjects';

const useStyles = makeStyles((theme) => ({
  timeline: {
    /* Hide scrollbar for Chrome, Safari and Opera */
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    /* Hide scrollbar for IE, Edge and Firefox */
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    overflowX: 'auto',
    width: '100%',
    height: '150px',
  },
  svg: {
    overflow: 'visible',
  },
}));

export function RecordsTimeline({ currentDate }) {
  const theme = useTheme();
  const classes = useStyles();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const svgRef = useRef(null);
  const hourDatesOfTheDay = () => {
    const currentTime = moment();
    const dates = [];
    for (let i = 0; i <= 24; i++) {
      dates.push(currentTime.clone().startOf('day').add(i, 'hours'));
    }
    return dates;
  };

  const times = hourDatesOfTheDay().map((date, index) => {
    const label = date.format('HH');
    const position = (index / 24) * 100;
    return { label, position };
  });

  const scrollToElement = () => {
    const elementToScrollTo = svgRef.current.querySelector('#hour-text-06');
    if (elementToScrollTo) {
      elementToScrollTo.scrollIntoView({
        block: 'nearest',
        inline: 'start',
      });
    }
  };

  useEffect(() => {
    scrollToElement();
  }, []);

  return (
    <div className={classes.timeline}>
      <svg ref={svgRef} width={650} height="25px" className={classes.svg}>
        {times.map((time, index) => (
          <React.Fragment key={index}>
            <text
              id={`hour-text-${time.label}`}
              x={`${time.position}%`}
              y="50%"
              fontSize="10"
              textAnchor="middle"
              stroke="white"
              fontWeight="normal"
              fontFamily="Arial"
            >
              {time.label}
            </text>

            {/* <line
              x1={`${time.position}%`}
              y1="35%"
              x2={`${time.position}%`}
              y2="55%"
              stroke="white"
              strokeWidth={0.5}
              strokeOpacity={0.5}
            /> */}
          </React.Fragment>
        ))}
      </svg>
      <RecordsTimelineProjects
        currentDate={currentDate}
      ></RecordsTimelineProjects>
    </div>
  );
}
