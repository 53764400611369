import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { loadInitialCalendarData } from 'scenes/WorkDemo/store/thunks/loadInitialCalendarData.thunk';
import { loadResourcesOfProject } from './thunks/loadResourcesOfProject';
import { loadUserResourcesForProject } from './thunks/loadUserResourcesForProject.thunk';
import { loadUserProjectsAndResources } from 'store/projects/thunks/project.thunks';
import { onSearchBusinessClick } from 'scenes/Settings/store/thunks/onSearchBusinessClick.thunk';
import { loadProjectRecordsFromToday } from 'store/records/thunks/loadProjectRecordsFromToday.thunk';
import { leaveProject } from './thunks/leaveProject.thunk';
import { addEmployerForUser } from 'store/projects/thunks/addEmployerForUser.thunk';
import { userJoinProject } from 'store/projects/thunks/userJoinProject.thunk';
import { editRecords } from 'store/records/thunks/editRecords.thunk';
import { editRecord } from 'store/records/thunks/editRecord';
import {
  loggedInUserResourcesAdapter,
  resourcesAdapter,
  resourcesToMakeAdminOfAdapter,
} from './resourceSliceSelectors';
import { loadResourcesAndResourceEmployersOfProject } from './thunks/loadResourcesAndResourceEmployersOfProject';
import { setLoadedResourcesProjectId } from 'store/records/actions/records.actions';
import { createProject } from 'store/projects/thunks/createProject.thunk';
import { createOrUpdateUser } from 'store/user/thunks/createOrUpdateUser.thunk';
import { clearGalleryData } from 'scenes/ProjectGallery/store/actions/projectGallery.actions';
import { closePermissionsView } from 'scenes/Permissions/store/actions/permissions-view.actions';
import { loadResourcesOfProjectWithEmployerData } from './thunks/loadResourcesOfProjectWithEmployerData';
import { closeProjectResourcesView } from 'scenes/ProjectResources/store/actions/project-resources.actions';

const initialState = {
  loadedResourcesProjectIds: [],
  resources: resourcesAdapter.getInitialState(),
  loggedInUserResources: loggedInUserResourcesAdapter.getInitialState(),
  resourcesToMakeAdminOf: resourcesToMakeAdminOfAdapter.getInitialState(),
  deleteResourcePending: false,
  loading: false,
  loadingResourcesToMakeAdminOf: false,
  deleteResourceError: '',
};

const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadResourcesOfProject.pending, (state) => {
        state.loading = true;
      })

      .addCase(loadResourcesOfProject.fulfilled, (state, action) => {
        state.loading = false;
        resourcesAdapter.setMany(state.resources, action.payload);
      })
      .addCase(loadResourcesOfProjectWithEmployerData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        loadResourcesOfProjectWithEmployerData.fulfilled,
        (state, action) => {
          state.loading = false;
          resourcesAdapter.setMany(state.resources, action.payload.resources);
        }
      )
      .addCase(setLoadedResourcesProjectId, (state, action) => {
        state.loadedResourcesProjectIds = [
          ...state.loadedResourcesProjectIds,
        ].includes(action.payload)
          ? [...state.loadedResourcesProjectIds]
          : [...state.loadedResourcesProjectIds, action.payload];
      })

      .addCase(loadResourcesOfProject.rejected, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
      }))
      .addCase(loadUserResourcesForProject.pending, (state) => {
        state.loading = true;
        state.isUserProjectResourcesLoaded = false;
      })
      .addCase(loadUserResourcesForProject.fulfilled, (state, action) => {
        state.loading = false;
        const userResourcesForProject = action.payload.filter(
          (resource) => resource && resource.id
        );

        resourcesAdapter.setMany(state.resources, userResourcesForProject);
        loggedInUserResourcesAdapter.setMany(
          state.loggedInUserResources,
          userResourcesForProject
        );

        state.isUserProjectResourcesLoaded = true;
      })
      .addCase(loadUserResourcesForProject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error;
        state.isUserProjectResourcesLoaded = true;
      })
      .addCase(createProject.fulfilled, (state, action) => {
        resourcesAdapter.setOne(state.resources, action.payload.newResource);
        loggedInUserResourcesAdapter.setOne(
          state.loggedInUserResources,
          action.payload.newResource
        );
      })
      .addCase(loadInitialCalendarData.fulfilled, (state, action) => {
        resourcesAdapter.setMany(state.resources, action.payload.resources);
      })

      .addCase(loadUserProjectsAndResources.fulfilled, (state, action) => {
        resourcesAdapter.setMany(state.resources, action.payload.resources);
        loggedInUserResourcesAdapter.setMany(
          state.loggedInUserResources,
          action.payload.resources
        );
      })
      .addCase(onSearchBusinessClick.pending, (state) => {
        state.loadingResourcesToMakeAdminOf = true;
        resourcesToMakeAdminOfAdapter.removeAll(state.resourcesToMakeAdminOf);
      })
      .addCase(onSearchBusinessClick.rejected, (state) => {
        state.loadingResourcesToMakeAdminOf = false;
      })
      .addCase(onSearchBusinessClick.fulfilled, (state, action) => {
        state.loadingResourcesToMakeAdminOf = false;
        resourcesToMakeAdminOfAdapter.setMany(
          state.resourcesToMakeAdminOf,
          action.payload.resources
        );
      })
      .addCase(loadProjectRecordsFromToday.fulfilled, (state, action) => {
        state.resources = resourcesAdapter.setMany(
          state.resources,
          action.payload.projectRecordsResources
        );
      })
      .addCase(editRecords.fulfilled, (state, action) => {
        state.resources = resourcesAdapter.setMany(
          state.resources,
          action.payload.resources
        );
      })
      .addCase(addEmployerForUser.fulfilled, (state, action) => {
        state.resources = resourcesAdapter.setOne(
          state.resources,
          action.payload.newResource
        );
        loggedInUserResourcesAdapter.setOne(
          state.loggedInUserResources,
          action.payload.newResource
        );
      })
      .addCase(userJoinProject.fulfilled, (state, action) => {
        resourcesAdapter.setMany(state.resources, action.payload.resources);
        loggedInUserResourcesAdapter.setMany(
          state.loggedInUserResources,
          action.payload.resources
        );
      })
      .addCase(leaveProject.fulfilled, (state, action) => {
        resourcesAdapter.removeOne(state.resources, action.payload.id);
        loggedInUserResourcesAdapter.removeOne(
          state.loggedInUserResources,
          action.payload.id
        );

        state.deleteResourcePending = false;
      })
      .addCase(leaveProject.pending, (state, action) => {
        state.deleteResourcePending = true;
      })
      .addCase(leaveProject.rejected, (state, action) => {
        state.deleteResourcePending = false;
        state.deleteResourceError = action.payload.message;
      })
      .addCase(editRecord.fulfilled, (state, action) => {
        if (action.payload.resource) {
          state.resources = resourcesAdapter.setOne(
            state.resources,
            action.payload.resource
          );
        }
      })
      .addCase(
        loadResourcesAndResourceEmployersOfProject.fulfilled,
        (state, action) => {
          if (action.payload.resources) {
            const resources = action.payload.resources.filter(
              (resource) => resource.id
            );

            state.resources = resourcesAdapter.setMany(
              state.resources,
              resources
            );
          }
        }
      )
      .addCase(createOrUpdateUser.fulfilled, (state, action) => {
        if (action.payload.resource) {
          resourcesAdapter.setOne(state.resources, action.payload.resource);
        }
      })
      .addCase(clearGalleryData, (state, action) => {
        state.resources = resourcesAdapter.getInitialState();
      })
      .addCase(closePermissionsView, (state, action) => {
        state.resources = resourcesAdapter.getInitialState();
      })
      .addCase(closeProjectResourcesView, (state, action) => {
        state.resources = resourcesAdapter.getInitialState();
      });
  },
});

export default resourcesSlice.reducer;
