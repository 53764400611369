import { createSelector } from '@reduxjs/toolkit';
import { BUSINESS_TYPE, RECORD_TYPE } from 'LixaniAPI/enums';
import dayjs from 'dayjs';
import {
  selectProjectsArray,
  selectSelectedProject,
  selectSelectedProjectId,
} from 'store/projects/selectors/projectsSelectors';
import {
  selectSelectedProjectAndChildProjectRecords,
  selectSelectedRecordsIds,
} from 'store/records/selectors/records.selectors';
import sort from 'utils/sort';
import returnProjectIdAndItsChildProjectIds from '../../utils/returnProjectIdAndItsChildProjectIds';
import {
  selectAllAssignments,
  selectAllFilePermissions,
  selectAssignmentEntities,
} from './workDemoSliceSelectors';

import { orderBy, uniq, uniqBy, uniqWith } from 'lodash-es';
import {
  EDIT_OTHER_USERS_RECORDS,
  RECORDS_EDIT_OWN_RECORDS,
} from 'scenes/Permissions/constants/permissions';
import {
  getNearestUserProjectPermissions,
  selectResourceDefaultPermissions,
  selectUserAdminBusinessIds,
  selectUserProjectPermissions,
} from 'scenes/Permissions/store/selectors/personalPermissionsSelectors';
import { selectCurrentUser } from 'store/selectors/root.selectors';

import { uniqueResourcesByUserAndEmployer } from 'scenes/WorkDemo/utils/uniqueResourcesByUserAndEmployer';
import { selectEmployeeBusinessConfigurationsByBusinessId } from 'store/projectUsers/selectors/projectUsers.selectors';
import { selectProjectsThatUserHasResourceToOrIsCreatorOf } from 'store/projects/selectors/userProjects.selectors';
import { selectPersonalRecordsWithAllowanceIds } from 'store/recordAllowances/selectors/record-allowances.selectors';
import { selectWorkCalendarRecordsArray } from 'store/records/selectors/records-base.selectors';
import {
  selectCurrentUserEmployerProjects,
  selectLoggedInUserResourcesArray,
} from 'store/resources/selectors/resources.selectors';
import { arrayToMap } from 'utils/arrayToMap';
import dayjsDiffHoursSimple from 'utils/dayjsDiffHoursSimple';
import {
  selectSelectedApprovalGroupCodes,
  selectSelectedAssignmentIds,
  selectSelectedEmployersBusinessIds,
  selectSelectedProjectIds,
  selectSelectedResourceIds,
  selectSelectedUserIds,
  selectWorkSearchParamsDescriptionSearchText,
  selectWorkSearchParamsFromDate,
  selectWorkSearchParamsToDate,
  selectWorkSearchParamsVisibleWeeks,
  selectWorkSearchStateFromDateAndToDate,
} from './workSearchParamsSelectors';

const selectWorkCalendar = (state) => state.workCalendar;

export const selectedWorkCalendarResources = createSelector(
  [selectWorkCalendar],
  (state) => state.resources.entities
);
export const selectedWorkCalendarProjects = createSelector(
  [selectWorkCalendar],
  (state) => state.projects.entities
);
export const selectedWorkCalendarEmployers = createSelector(
  [selectWorkCalendar],
  (state) => state.employers.entities
);
export const selectedWorkCalendarResourcesArray = createSelector(
  [selectedWorkCalendarResources],
  (resources) => {
    return Object.values(resources);
  }
);
export const selectedWorkCalendarProjectsArray = createSelector(
  [selectedWorkCalendarProjects],
  (projects) => Object.values(projects)
);
export const selectedWorkCalendarEmployersArray = createSelector(
  [selectedWorkCalendarEmployers],
  (employers) => Object.values(employers)
);

export const selectWorkCalendarResourcesOfSelectedProject = createSelector(
  [
    selectedWorkCalendarResourcesArray,
    selectSelectedProject,
    selectedWorkCalendarProjectsArray,
    selectedWorkCalendarEmployersArray,
  ],
  (resources, selectedProject, workdemoProjects, employers) => {
    const selectedProjectId = selectedProject.id;
    const isEmployerProject = selectedProject.businessTypeEnum === 'EMPLOYER';

    const selectedProjectAndChildProjectIds =
      returnProjectIdAndItsChildProjectIds(
        [selectedProjectId],
        workdemoProjects
      );

    const employerCopiesIds = isEmployerProject
      ? employers
          .filter(
            (employer) =>
              employer.id &&
              employer.business_id === selectedProject.business_id
          )
          .map((employer) => employer.id)
      : [];

    // employer copies
    return resources.filter((resource) => {
      const isResourceOfSelectedProjectOrChildProject =
        selectedProjectAndChildProjectIds.includes(resource.project);

      const resourceHasSelectedProjectAsEmployer =
        resource.employer === selectedProjectId ||
        employerCopiesIds.includes(resource.employer);

      return (
        isResourceOfSelectedProjectOrChildProject ||
        resourceHasSelectedProjectAsEmployer
      );
    });
  }
);

export const selectWorkCalendarProjectsOfSelectedProject = createSelector(
  [
    selectedWorkCalendarProjectsArray,
    selectWorkCalendarResourcesOfSelectedProject,
  ],
  (projects, resources) => {
    const resourceProjectIds = resources.map((resource) => resource.project);
    return projects.filter((project) =>
      resourceProjectIds.includes(project.id)
    );
  }
);

export const selectWorkCalendarResourcesProjectsEmployersIds = createSelector(
  [
    selectWorkCalendarResourcesOfSelectedProject,
    selectedWorkCalendarProjectsArray,
  ],
  (resources, projects) => {
    const resourceProjectIds = resources.map((resource) => resource.project);

    return projects
      .filter((project) => resourceProjectIds.includes(project.id))
      .map((project) => project.masterParent);
  }
);

export const selectWorkCalendarEmployersOfSelectedProject = createSelector(
  [
    selectedWorkCalendarEmployersArray,
    selectWorkCalendarResourcesOfSelectedProject,
    selectWorkCalendarResourcesProjectsEmployersIds,
  ],
  (employers, resources, resourceProjectEmployerIds) => {
    const resourceEmployerIds = resources.map((resource) => resource.employer);

    const employersIds = [
      ...resourceEmployerIds,
      ...resourceProjectEmployerIds,
    ];

    return employers.filter((employer) => employersIds.includes(employer.id));
  }
);

export const selectSelectedProjectsData = createSelector(
  [
    selectSelectedProjectId,
    selectWorkCalendarResourcesOfSelectedProject,
    selectWorkCalendarProjectsOfSelectedProject,
    selectWorkCalendarEmployersOfSelectedProject,
  ],
  (selectedProjectId, resources, projects, employers) => {
    if (selectedProjectId) {
      return {
        resources: resources,
        projects: projects,
        employers: employers,
      };
    } else {
      return undefined;
    }
  }
);

export const selectIsPersonalWorkCalendarMode = createSelector(
  [selectWorkCalendar],
  (state) => state.isPersonalWorkCalendarMode
);

export const selectWorkCalendarProjectsArray = createSelector(
  [
    selectSelectedProjectsData,
    selectIsPersonalWorkCalendarMode,
    selectProjectsArray,
  ],
  (selectedProjectData, isPersonalWorkCalendarMode, projects) => {
    if (isPersonalWorkCalendarMode) {
      return projects;
    }

    if (selectedProjectData) {
      return selectedProjectData.projects;
    } else {
      return [];
    }
  }
);

const selectProjects = createSelector(
  [selectWorkCalendarProjectsArray],
  (projects) => {
    return arrayToMap(projects);
  }
);

export const selectIsProjectDataLoading = createSelector(
  [selectWorkCalendar],
  (state) => {
    return state.projectsDataLoading;
  }
);

export const selectEmployerProjectsArray = createSelector(
  [
    selectSelectedProjectsData,
    selectIsPersonalWorkCalendarMode,
    selectCurrentUserEmployerProjects,
  ],
  (selectedProjectData, isPersonalWorkCalendarMode, personalEmployers) => {
    if (isPersonalWorkCalendarMode) {
      return personalEmployers;
    }
    if (selectedProjectData) {
      return selectedProjectData.employers;
    } else {
      return [];
    }
  }
);

const selectEmployerProjectEntities = createSelector(
  [selectEmployerProjectsArray],
  (employersArray) => {
    return arrayToMap(employersArray);
  }
);

export const selectProjectsDataResourcesArray = createSelector(
  [
    selectSelectedProjectsData,
    selectIsPersonalWorkCalendarMode,
    selectLoggedInUserResourcesArray,
  ],
  (selectedProjectData, isPersonalWorkCalendarMode, personalResources) => {
    if (isPersonalWorkCalendarMode) {
      return personalResources;
    }
    if (selectedProjectData) {
      return selectedProjectData.resources;
    } else {
      return [];
    }
  }
);

const selectProjectsDataResourceEntities = createSelector(
  [selectProjectsDataResourcesArray],
  (resourcesArray) => {
    return arrayToMap(resourcesArray);
  }
);

const selectRecordsWhichResourceHasSelectedProjectAsEmployer = createSelector(
  [
    selectWorkCalendarRecordsArray,
    selectSelectedProject,
    selectProjectsDataResourceEntities,
    selectEmployerProjectEntities,
  ],
  (records, selectedProject, resources, employers) => {
    return records.filter((record) => {
      const recordsResource = resources[record.resource];

      const resourceEmployer = recordsResource
        ? employers[recordsResource.employer]
        : undefined;

      return (
        (recordsResource &&
          recordsResource.employer &&
          recordsResource.employer === selectedProject.id) ||
        (resourceEmployer &&
          resourceEmployer.business_id &&
          resourceEmployer.business_id === selectedProject.business_id)
      );
    });
  }
);

export const selectAssignmentsArray = createSelector(
  [selectAllAssignments],
  (assignments) => Object.values(assignments)
);

export const selectAssignmentsArrayWithoutDeleted = createSelector(
  [selectAssignmentsArray],
  (assignments) => assignments.filter((assignment) => !assignment.deletedAt)
);

export const selectRecordDialogData = createSelector(
  [selectWorkCalendar],
  (workCalendarState) => workCalendarState.records_demo.recordDialogInitialData
);

export const selectWorkCalendarLoading = createSelector(
  [selectWorkCalendar],
  (workCalendarState) => workCalendarState.loading
);

const selectSelectedProjectAndChildProjectsFilePermissionsArray =
  createSelector(
    [
      selectAllFilePermissions,
      selectWorkCalendarProjectsArray,
      selectEmployerProjectsArray,
      selectSelectedProjectId,
    ],
    (filePermissions, projects, employers, selectedProjectId) => {
      const allProjects = [...projects, ...employers];

      const projectAndChildProjects = returnProjectIdAndItsChildProjectIds(
        [selectedProjectId],
        allProjects
      );

      return filePermissions.filter((fp) =>
        projectAndChildProjects.includes(fp.project)
      );
    }
  );

const selectLoggedInUserFilePermissionsArray = createSelector(
  [selectAllFilePermissions, selectCurrentUser],
  (filePermissions, user) => {
    const { firstName, lastName } = user.resource;
    return filePermissions
      .filter((filePermission) => filePermission.user === user.id)
      .map((filePermission) => ({
        ...filePermission,
        user: { firstName, lastName, id: user.id, resource: user.resource },
      }));
  }
);

export const selectProjectsFilePermissionsInTimeFrame = createSelector(
  [
    selectWorkSearchStateFromDateAndToDate,
    selectSelectedProjectAndChildProjectsFilePermissionsArray,
  ],
  (dates, filePermissions) => {
    const fromDate = dayjs(dates.fromDate);
    const toDate = dayjs(dates.toDate);

    return filePermissions.filter((filePermission) => {
      const createdAt = dayjs(filePermission.createdAt);
      const isBeforeOrSameDay =
        createdAt.isBefore(toDate) || createdAt.isSame(toDate, 'day');
      const isAfterOrSameDay =
        createdAt.isAfter(fromDate) || createdAt.isSame(fromDate, 'day');

      return isBeforeOrSameDay && isAfterOrSameDay;
    });
  }
);

export const selectProjectsImagesAndVideosInTimeFrame = createSelector(
  [selectProjectsFilePermissionsInTimeFrame],
  (filePermissions) => {
    const fps = filePermissions.filter((fp) => fp.file && fp.file?.mimeType);
    const images = fps.filter((filePermission) =>
      filePermission.file.mimeType.includes('image')
    );
    const videos = fps.filter((filePermission) =>
      filePermission.file.mimeType.includes('video')
    );

    return { images, videos };
  }
);

export const selectCurrentUserFilePermissionsForDay = createSelector(
  [
    selectLoggedInUserFilePermissionsArray,
    selectWorkCalendarProjectsArray,
    (_, args) => args,
  ],
  (filePermissions, projects, args) => {
    const { day } = args;
    const dayFiles = filePermissions.filter(
      (filePermission) =>
        filePermission.type === 'FILE' &&
        day.format('YYYYMMDD') ===
          dayjs(filePermission.createdAt).format('YYYYMMDD') &&
        filePermission.file?.mimeType
    );

    const dayFilesWithProjectData = dayFiles.map((file) => {
      const fileProject = projects.find(
        (project) => project.id === file.project
      );
      return {
        ...file,
        project: fileProject ? fileProject : file.project,
      };
    });

    const mediaFiles = dayFilesWithProjectData.filter(
      (filePermission) =>
        filePermission.file.mimeType.includes('image') ||
        filePermission.file.mimeType.includes('video')
    );

    const documentFiles = dayFilesWithProjectData.filter(
      (filePermission) =>
        filePermission.file.mimeType.includes('application') ||
        filePermission.file.mimeType.includes('text')
    );

    return { mediaFiles, documentFiles };
  }
);

export const selectUsersFilePermissionsForDay = createSelector(
  [
    selectSelectedProjectAndChildProjectsFilePermissionsArray,
    (_, args) => args,
  ],
  (filePermissions, args) => {
    const { day, user } = args;
    const dayFiles = filePermissions.filter(
      (p) =>
        p.user === user &&
        p.type === 'FILE' &&
        day.format('YYYYMMDD') === dayjs(p.createdAt).format('YYYYMMDD') &&
        p.file?.mimeType
    );

    const mediaFiles = dayFiles.filter(
      (filePermission) =>
        filePermission.file.mimeType.includes('image') ||
        filePermission.file.mimeType.includes('video')
    );

    const documentFiles = dayFiles.filter(
      (filePermission) =>
        filePermission.file.mimeType.includes('application') ||
        filePermission.file.mimeType.includes('text')
    );

    return { mediaFiles, documentFiles };
  }
);

export const selectSelectedResourceOptions = createSelector(
  selectProjectsDataResourcesArray,
  selectSelectedResourceIds,
  selectProjects,
  (resources, selectedResourceIds, projects) => {
    const selectedResources = resources.filter((resource) =>
      selectedResourceIds.includes(resource.id)
    );

    let options = selectedResources.map((resource) => {
      if (resource === null) {
        return {};
      }

      return {
        value: resource.id,
        label: `${
          projects[resource.project].name ? projects[resource.project].name : ''
        } / ${resource.lastName} ${resource.firstName}`,
        type: 'selected',
      };
    });
    options.sort((a, b) => sort.sortByKey(a, b, 'label'));

    return options;
  }
);

export const selectSelectedAssignmentOptions = createSelector(
  selectAssignmentsArrayWithoutDeleted,
  selectSelectedAssignmentIds,
  selectProjects,
  (assignments, selectedAssignmentIds, projects) => {
    const selectedAssignments = assignments.filter((assignment) =>
      selectedAssignmentIds.includes(assignment.id)
    );

    let options = selectedAssignments.map((assignment) => {
      if (assignment === null) {
        return {};
      }

      const assignmentProjectName =
        projects[assignment.project] && projects[assignment.project].name
          ? projects[assignment.project].name
          : '';
      return {
        id: assignment.id,
        value: assignment.id,
        parent: assignment.parent ? assignment.parent : false,
        label: `${nestedAssignmentNumber(assignment, assignments)}. ${
          assignment.name
        }`,
        type: assignmentProjectName,
        project: assignment.project,
      };
    });
    options.sort((a, b) => sort.sortByKey(a, b, 'label'));

    return options;
  }
);

export const selectSelectedEmployerOptions = createSelector(
  selectEmployerProjectsArray,
  selectSelectedEmployersBusinessIds,
  (employers, employerBusinessIds) => {
    const selectedEmployers = employers.filter((employer) =>
      employerBusinessIds.includes(employer.business_id)
    );

    let options = selectedEmployers.map((employer) => {
      return {
        value: employer.business_id,
        label: employer.name,
        type: 'selected',
      };
    });
    options.sort((a, b) => sort.sortByKey(a, b, 'label'));
    return uniqBy(options, 'value');
  }
);

export const selectSelectedProjectAndChildProjectAndResourceHasSelectedProjectAsEmployerRecords =
  createSelector(
    [
      selectSelectedProjectAndChildProjectRecords,
      selectRecordsWhichResourceHasSelectedProjectAsEmployer,
    ],
    (
      projectAndChildProjectsRecords,
      resourceHasSelectedProjectAsEmployerRecords
    ) => {
      let recordsUniqued = uniqBy(
        [
          ...projectAndChildProjectsRecords,
          ...resourceHasSelectedProjectAsEmployerRecords,
        ],
        'id'
      );
      return recordsUniqued;
    }
  );

export const selectResourcesWithSelectedProjectAsEmployer = createSelector(
  [
    selectProjectsDataResourcesArray,
    selectSelectedProject,
    selectEmployerProjectEntities,
  ],
  (resources, selectedProject, employers) => {
    return resources.filter((resource) => {
      const resourceEmployer = employers[resource.employer];
      return (
        resource.employer === selectedProject.id ||
        (resourceEmployer &&
          selectedProject &&
          resourceEmployer.business_id &&
          selectedProject.business_id &&
          resourceEmployer.business_id === selectedProject.business_id)
      );
    });
  }
);

export const selectSelectedProjectAndChildProjects = createSelector(
  [selectWorkCalendarProjectsArray, selectSelectedProjectId],
  (projects, selectedProject) => {
    const childProjectIds = returnProjectIdAndItsChildProjectIds(
      [selectedProject],
      projects
    );

    const projectAndChildProjects = projects.filter((project) =>
      childProjectIds.includes(project.id)
    );
    return projectAndChildProjects;
  }
);

export const selectProjectsWhichResourceHasSelectedProjectAsEmployer =
  createSelector(
    [
      selectWorkCalendarProjectsArray,
      selectResourcesWithSelectedProjectAsEmployer,
    ],
    (projects, resources) => {
      const projectIdsOfResources = uniq(
        resources.map((resource) => resource.project)
      );

      return projects.filter((project) =>
        projectIdsOfResources.includes(project.id)
      );
    }
  );

export const selectProjectsOfSelectedProject = createSelector(
  [
    selectProjectsWhichResourceHasSelectedProjectAsEmployer,
    selectSelectedProjectAndChildProjects,
  ],
  (resourceProjects, projects) => {
    return [...resourceProjects, ...projects];
  }
);

export const selectRecordsBasedOnWorkCalendarMode = createSelector(
  [
    selectIsPersonalWorkCalendarMode,
    selectSelectedProjectAndChildProjectAndResourceHasSelectedProjectAsEmployerRecords,
    selectPersonalRecordsWithAllowanceIds,
  ],
  (isPersonalWorkCalendarMode, projectRecords, personalRecords) =>
    isPersonalWorkCalendarMode ? personalRecords : projectRecords
);

export const selectWorkCalendarFilteredRecords = createSelector(
  [
    selectRecordsBasedOnWorkCalendarMode,
    selectProjectsDataResourceEntities,
    selectWorkSearchParamsVisibleWeeks,
    selectSelectedEmployersBusinessIds,
    selectSelectedProjectIds,
    selectSelectedResourceIds,
    selectSelectedAssignmentIds,
    selectWorkSearchParamsFromDate,
    selectWorkSearchParamsToDate,
    selectWorkSearchParamsDescriptionSearchText,
    selectSelectedUserIds,
    selectEmployerProjectEntities,
    selectSelectedApprovalGroupCodes,
  ],
  (
    records,
    resources,
    visibleWeeks,
    selectedEmployerBusinessIds,
    selectedProjectIds,
    selectedResourceIds,
    selectedAssignmentIds,
    fromDate,
    toDate,
    descriptionSearchText,
    selectedUserIds,
    employers,
    approvalGroupCodes
  ) => {
    const isInVisibleWeeks = (record) =>
      visibleWeeks.includes(
        dayjs(record.clockInAt).startOf('isoWeek').format()
      );
    const isInSelectedTimeframe = (record) =>
      record.clockInAt > fromDate && record.clockInAt < toDate;
    const isInSelectedProjectsOrNoProjectsSelected = (record) =>
      selectedProjectIds.length === 0 ||
      selectedProjectIds.includes(record.project);
    const isInSelectedApprovalGroupCodes = (record) =>
      approvalGroupCodes.length === 0 ||
      approvalGroupCodes.includes(record.groupCode);

    const isInSelectedUsersOrNoUserSelected = (record) =>
      selectedUserIds.length === 0 ||
      selectedUserIds.includes(resources[record.resource]?.user);
    const isInSelectedEmployersOrNoEmployersSelected = (record) =>
      selectedEmployerBusinessIds.length === 0 ||
      selectedEmployerBusinessIds.includes(
        employers[resources[record.resource]?.employer]?.business_id
      );
    const isInSelectedResourcesOrNoResourcesSelected = (record) =>
      selectedResourceIds.length === 0 ||
      selectedResourceIds.includes(record.resource);
    const hasAnySelectedAssignmentOrNoAssignmentsSelected = (record) =>
      selectedAssignmentIds.length === 0 ||
      selectedAssignmentIds.some((id) => id === record.assignment);
    const isNotDeleted = (record) =>
      record.deletedAt === null || !record.hasOwnProperty('deletedAt');

    const descriptionSeachTextsArray = descriptionSearchText
      ? descriptionSearchText.trim().split(',')
      : [];
    const descriptionSearchTextsTrimmed = descriptionSeachTextsArray
      .filter((text) => text)
      .map((text) => text.trim());

    const isSearchText = descriptionSearchTextsTrimmed.length > 0;

    const descriptionMatchesSearchText = (record) => {
      let isIncluded = false;
      descriptionSearchTextsTrimmed.forEach((text) => {
        if (
          record.description &&
          record.description.trim().toUpperCase().includes(text)
        ) {
          isIncluded = true;
        }
      });
      return isSearchText ? isIncluded : true;
    };

    const timeframeRecords = records.filter(
      (record) =>
        isInVisibleWeeks(record) &&
        isInSelectedTimeframe(record) &&
        isInSelectedEmployersOrNoEmployersSelected(record) &&
        isInSelectedProjectsOrNoProjectsSelected(record) &&
        isInSelectedResourcesOrNoResourcesSelected(record) &&
        hasAnySelectedAssignmentOrNoAssignmentsSelected(record) &&
        isNotDeleted(record) &&
        descriptionMatchesSearchText(record) &&
        isInSelectedUsersOrNoUserSelected(record) &&
        isInSelectedApprovalGroupCodes(record)
    );

    return timeframeRecords;
  }
);

export const selectProjectsDataResourcesOfWorkCalendarRecords = createSelector(
  [selectProjectsDataResourcesArray, selectWorkCalendarFilteredRecords],
  (projectsDataResources, workCalendarRecords) => {
    const workCalendarRecordsResourceIds = uniq(
      workCalendarRecords.map((record) => record.resource)
    );

    return projectsDataResources.filter((resource) =>
      workCalendarRecordsResourceIds.includes(resource.id)
    );
  }
);
export const selectProjectsDataResourcesArrayWithEmployeeConfigurationInformation =
  createSelector(
    [
      selectProjectsDataResourcesOfWorkCalendarRecords,
      selectEmployeeBusinessConfigurationsByBusinessId,
      selectSelectedEmployersBusinessIds,
    ],
    (resources, employeeConfigurations, selectedBusinessIds) => {
      return resources.map((resource) => {
        const foundEmployeeConfigurationForResource = employeeConfigurations[
          selectedBusinessIds[0]
        ]
          ? employeeConfigurations[selectedBusinessIds[0]].find(
              (employeeConfiguration) =>
                employeeConfiguration.userId === resource.user
            )
          : undefined;

        return {
          ...resource,
          baseSalaryType: foundEmployeeConfigurationForResource
            ? foundEmployeeConfigurationForResource.baseSalaryType
            : 'HOURLY',
          salaryMonth: foundEmployeeConfigurationForResource?.salaryMonth,
          salaryHour: foundEmployeeConfigurationForResource?.salaryHour,
          monthSalaryFrequency:
            foundEmployeeConfigurationForResource?.monthSalaryFrequency,
        };
      });
    }
  );

export const selectRecordsByWeekUserEmployerKey = createSelector(
  [
    selectWorkCalendarFilteredRecords,
    selectProjectsDataResourceEntities,
    selectEmployerProjectsArray,
  ],
  (records, resources, employerProjects) => {
    const weekRecordsByUserEmployerKey = [];

    records.forEach((record) => {
      const foundRecordResource = resources[record.resource];

      if (foundRecordResource && foundRecordResource.user) {
        const resourceEmployerProject = employerProjects.find(
          (employer) => employer.id === foundRecordResource.employer
        );

        const employerKey = resourceEmployerProject
          ? resourceEmployerProject.business_id
          : foundRecordResource.employer;

        const weekUserEmployerKey = `${dayjs(record.clockInAt)
          .startOf('isoWeek')
          .format()}/${employerKey}/${foundRecordResource.user}`;

        weekRecordsByUserEmployerKey[weekUserEmployerKey] =
          weekRecordsByUserEmployerKey[weekUserEmployerKey] || [];
        weekRecordsByUserEmployerKey[weekUserEmployerKey].push(record);
      } else {
        if (process.env.NODE_ENV !== 'production') {
          console.log('record without resource or user', record);
        }
      }
    });

    return weekRecordsByUserEmployerKey;
  }
);

export const selectRecordsByBusinessId = createSelector(
  [
    selectWorkCalendarFilteredRecords,
    selectSelectedRecordsIds,
    selectProjectsDataResourceEntities,
    selectEmployerProjectsArray,
  ],
  (allRecords, selectedRecordsIds, resources, employersArray) => {
    const records =
      selectedRecordsIds.length > 0
        ? allRecords.filter((record) => selectedRecordsIds.includes(record.id))
        : allRecords;

    const employersBusinessIds = uniq([
      ...employersArray.map((employer) => employer.business_id),
      'MyLixani',
    ]);

    const recordsByBusinessId = employersBusinessIds.map((businessId) => {
      let employer = employersArray.find(
        (employer) =>
          employer.business_id === businessId &&
          employer.businessTypeEnum === 'EMPLOYER'
      );

      if (!employer) {
        employer = { business_id: businessId, name: 'MyLixani' };
      }

      // records resources employer business id matches employers business id
      const recordsOfEmployer = records
        .filter((record) => record.resource)
        .filter((record) => {
          const recordsResource = resources[record.resource];

          const employerBusinessId =
            recordsResource && recordsResource.employer
              ? recordsResource.employerBusinessId
              : 'MyLixani';

          return businessId === employerBusinessId;
        });

      return {
        businessId: employer.business_id,
        name: employer.name,
        records: recordsOfEmployer,
      };
    });

    return recordsByBusinessId;
  }
);

export const selectRecordsTotalHoursByAssignments = createSelector(
  [
    selectWorkCalendarFilteredRecords,
    selectSelectedRecordsIds,
    selectAssignmentsArray,
  ],
  (allRecords, selectedRecordsIds, assignments) => {
    const records =
      selectedRecordsIds.length > 0
        ? allRecords.filter((record) => selectedRecordsIds.includes(record.id))
        : allRecords;

    const recordTotalHoursByAssignments = assignments.map((assignment) => {
      const assignmentId = assignment.id;

      const assignmentsRecords = records.filter(
        (record) => record.assignment === assignmentId
      );

      const hours = getTotalClockedAndBilledHours(assignmentsRecords);

      return {
        name: `${nestedAssignmentNumber(assignment, assignments)}. ${
          assignment.name
        }`,
        hoursClocked: hours.clockedHours,
        hoursBilled: hours.billedHours,
        id: assignment.id,
      };
    });

    return recordTotalHoursByAssignments.filter(
      (data) => data.hoursClocked > 0
    );
  }
);

export const selectAllRecordsTotalHours = createSelector(
  [
    selectWorkCalendarFilteredRecords,
    selectSelectedRecordsIds,
    selectProjectsDataResourceEntities,
  ],
  (allRecords, selectedRecordsIds, resources) => {
    const workRecordsWithFoundResource = allRecords.filter(
      (record) =>
        (record.type === RECORD_TYPE.WORK ||
          record.type === RECORD_TYPE.PROJECTWORK) &&
        resources[record.resource]
    );

    const records =
      selectedRecordsIds.length > 0
        ? allRecords.filter((record) => selectedRecordsIds.includes(record.id))
        : workRecordsWithFoundResource;

    return getTotalClockedAndBilledHours(records);
  }
);

const getTotalClockedAndBilledHours = (records) => {
  let clockedHours = 0;
  let billedHours = 0;

  records.forEach((record) => {
    const clockInAt = dayjs(record.clockInAt);
    const clockOutAt = dayjs(record.clockOutAt);
    const billedInAt = dayjs(record.billedClockInAt);
    const billedOutAt = dayjs(record.billedClockOutAt);
    const clockedInRecordMaxHours = 8.5;

    const clockDifference = clockOutAt.diff(clockInAt, 'minutes') / 60;
    //const billDifference = BilledOutAt.diff(BilledInAt, 'minutes') / 60;

    // if record is clocked in, add only max hours to total count
    if (record.clockOutAt) {
      clockedHours += calculateRecordHours(clockInAt, clockOutAt);
    } else if (clockDifference >= clockedInRecordMaxHours) {
      clockedHours += clockedInRecordMaxHours;
    } // if billed clocked out, add to total count. There should not be billed in records.
    if (record.billedClockOutAt) {
      billedHours += calculateRecordHours(billedInAt, billedOutAt);
    }
  });

  return { clockedHours, billedHours };
};

const calculateRecordHours = (clockInAt, clockOutAt) => {
  const recordHours = clockOutAt.diff(clockInAt, 'hours', true);

  return recordHours;
};

export const selectRecordsTotalHoursByBusinessId = createSelector(
  [selectRecordsByBusinessId],
  (businessIdRecordsObjects) => {
    return businessIdRecordsObjects
      .map((object) => {
        const hours = getTotalClockedAndBilledHours(object.records);

        return {
          businessId: object.businessId,
          name: object.name,
          hoursClocked: hours.clockedHours,
          hoursBilled: hours.billedHours,
        };
      })
      .filter((data) => data.hoursClocked > 0);
  }
);

export const selectFilteredRecordsTotalLength = createSelector(
  [selectWorkCalendarFilteredRecords],
  (records) => records.length
);

export const selectProjectAndChildProjectEditableRecords = createSelector(
  [
    selectSelectedProjectAndChildProjectAndResourceHasSelectedProjectAsEmployerRecords,
    selectUserProjectPermissions,
    selectProjects,
    selectResourceDefaultPermissions,
    selectCurrentUser,
    selectProjectsDataResourceEntities,
    selectUserAdminBusinessIds,
    selectEmployerProjectEntities,
  ],
  (
    records,
    userProjectPermissions,
    projects,
    resourceDefaultPermissions,
    user,
    resources,
    adminBusinessIds,
    employers
  ) => {
    const editableRecords = records
      .filter((record) => {
        const recordProject =
          projects && record ? projects[record.project] : {};

        const isOwnerOfRecordProject = recordProject
          ? recordProject.mirrorUser === user.id
          : false;

        const nearestUserProjectPermissionsForRecord =
          getNearestUserProjectPermissions(
            projects,
            userProjectPermissions,
            record.project
          );

        const defaultPermissionsWithNearestProjectPermissions = {
          ...resourceDefaultPermissions,
          ...nearestUserProjectPermissionsForRecord,
        };

        const isOwnRecord = record.mirrorUser === user.id;

        const isPermissionToEdit = isOwnRecord
          ? defaultPermissionsWithNearestProjectPermissions[
              RECORDS_EDIT_OWN_RECORDS
            ]
          : defaultPermissionsWithNearestProjectPermissions[
              EDIT_OTHER_USERS_RECORDS
            ];

        const isRecordCurrentlyClockedIn = record.status === 'IN';
        const isRecordPartOfApprovalGroup = record.groupCode ? true : false;

        const selectedRecordResource = record ? resources[record.resource] : {};

        let employerBusinessId =
          selectedRecordResource && selectedRecordResource.employerBusinessId
            ? selectedRecordResource.employerBusinessId
            : undefined;

        if (!employerBusinessId) {
          employerBusinessId =
            selectedRecordResource && selectedRecordResource.employer
              ? employers[selectedRecordResource.employer]
                ? employers[selectedRecordResource.employer].business_id
                : undefined
              : undefined;
        }

        const isAdminOfRecordResource = selectedRecordResource
          ? adminBusinessIds.includes(employerBusinessId)
          : false;

        const selectedRecordProject = record ? projects[record.project] : {};

        const employerBisnesIdByProject = selectedRecordProject
          ? selectedRecordProject.business_id
            ? selectedRecordProject.business_id
            : selectedRecordProject.masterParent
            ? projects[selectedRecordProject.masterParent].business_id
            : undefined
          : undefined;

        const isAdminOfRecordProject = selectedRecordProject
          ? adminBusinessIds.includes(employerBisnesIdByProject)
          : false;

        return (
          (isOwnerOfRecordProject ||
            isPermissionToEdit ||
            isAdminOfRecordResource ||
            isAdminOfRecordProject) &&
          !isRecordCurrentlyClockedIn &&
          !isRecordPartOfApprovalGroup
        );
      })
      .map((record) => record.id);
    return editableRecords;
  }
);

export const selectIsSelectedRecordsEditable = createSelector(
  [selectSelectedRecordsIds, selectProjectAndChildProjectEditableRecords],
  (selectedRecordsIds, editableRecordsIds) => {
    return selectedRecordsIds.every((recordId) =>
      editableRecordsIds.includes(recordId)
    );
  }
);

export const selectSubContractorResources = createSelector(
  [
    selectProjectsDataResourcesArray,
    selectProjects,
    selectEmployerProjectEntities,
    (_, args) => args,
  ],
  (resources, projects, employers, args) => {
    const projectId = args;
    let parentProjectId;
    let projectIds = [];

    {
      if (
        projects[projectId]?.businessTypeEnum === BUSINESS_TYPE.SUBCONTRACTOR
      ) {
        parentProjectId = projects[projects[projectId].parent].id;
      } else {
        parentProjectId = projectId;
      }

      const childrenIds = Object.values(projects)
        .filter(
          (proj) =>
            proj.parent &&
            proj.parent === parentProjectId &&
            proj.businessTypeEnum === BUSINESS_TYPE.SUBCONTRACTOR
        )
        .map((proj) => proj.id);

      projectIds = [parentProjectId, ...childrenIds];
    }

    let resourceOptions = resources
      .filter((resource) => projectIds.includes(resource.project))
      .map((resource) => {
        if (projects[resource.employer]) {
          resource = { ...resource, employer: projects[resource.employer] };
        } else if (employers[resource.employer]) {
          resource = { ...resource, employer: employers[resource.employer] };
        }
        return resource;
      });

    return resourceOptions;
  }
);

export const selectProjectAndChildProjectResourcesWithoutJoinedData =
  createSelector(
    [selectProjectsDataResourcesArray, selectProjects, selectSelectedProjectId],
    (resources, projects, selectedProjectId) => {
      const projectAndChildProjectIds = returnProjectIdAndItsChildProjectIds(
        [selectedProjectId],
        projects
      );

      return resources.filter((resource) =>
        projectAndChildProjectIds.includes(resource.project)
      );
    }
  );

export const selectExternalProjectOptions = createSelector(
  selectProjectsOfSelectedProject,
  selectEmployerProjectsArray,
  selectSelectedProjectId,
  (projects, employers, selectedProjectId) => {
    const allProjects = [...projects, ...employers];

    const projectAndChildProjectIds = returnProjectIdAndItsChildProjectIds(
      [selectedProjectId],
      allProjects
    );

    const options = projects
      .filter((project) => !projectAndChildProjectIds.includes(project.id))
      .map((project) => {
        const employer = employers.find(
          (employer) => project.masterParent === employer.id
        );

        return {
          id: project.id,
          label: `${project.name} ${
            project.projectKey ? ` (${project.projectKey})` : ''
          }`,
          projectKey: project.projectKey,
          type: employer ? employer.name : project.name,
          name: project.name,
        };
      });

    options.sort((a, b) => sort.sortByKey(a, b, 'label'));

    return uniqBy(options, 'id');
  }
);

export const selectSelectedProjectAndChildProjectOptions = createSelector(
  selectProjectsOfSelectedProject,
  selectEmployerProjectsArray,
  selectSelectedProjectId,
  (projects, employers, selectedProjectId) => {
    const allProjects = [...projects, ...employers];
    const projectAndChildProjectIds = returnProjectIdAndItsChildProjectIds(
      [selectedProjectId],
      allProjects
    );

    const options = projects
      .filter((project) => projectAndChildProjectIds.includes(project.id))
      .map((project) => {
        return {
          id: project.id,
          label: `${project.name} ${
            project.projectKey ? ` (${project.projectKey})` : ''
          }`,
          projectKey: project.projectKey,
          name: project.name,
          type: 'priority',
        };
      });

    options.sort((a, b) => sort.sortByKey(a, b, 'label'));

    return uniqBy(options, 'id');
  }
);

// these are projects that are created under employer project.
export const selectPersonalWorkCalendarProjectsOptionsWhichHaveEmployer =
  createSelector(
    [selectProjectsThatUserHasResourceToOrIsCreatorOf],
    (projects) => {
      const options = projects
        .filter(
          (project) =>
            // filter out myLixani projects
            !(!project.masterParent.id && project.businessTypeEnum === 'NORMAL')
        )
        .map((project) => {
          return {
            id: project.id,
            label: `${project.name} ${
              project.projectKey ? ` (${project.projectKey})` : ''
            }`,
            projectKey: project.projectKey,
            name: project.name,

            type:
              project.masterParent && project.masterParent.id
                ? project.masterParent.name
                : project.name,
          };
        });

      options.sort((a, b) => sort.sortByKey(a, b, 'label'));
      return uniqBy(options, 'id');
    }
  );

export const selectPersonalWorkCalendarMyLixaniProjectsOptions = createSelector(
  selectProjectsThatUserHasResourceToOrIsCreatorOf,
  (projects) => {
    const options = projects
      .filter(
        (project) =>
          !project.masterParent.id && project.businessTypeEnum === 'NORMAL'
      )
      .map((project) => {
        return {
          id: project.id,
          label: `${project.name} ${
            project.projectKey ? ` (${project.projectKey})` : ''
          }`,
          projectKey: project.projectKey,
          name: project.name,
          type: 'priority',
        };
      });

    options.sort((a, b) => sort.sortByKey(a, b, 'label'));
    return uniqBy(options, 'id');
  }
);

export const selectPersonalWorkCalendarProjectOptions = createSelector(
  [
    selectPersonalWorkCalendarProjectsOptionsWhichHaveEmployer,
    selectPersonalWorkCalendarMyLixaniProjectsOptions,
  ],
  (projectsWithEmployers, myLixaniProjects) => [
    ...projectsWithEmployers,
    ...myLixaniProjects,
  ]
);

export const selectAllProjectOptions = createSelector(
  selectExternalProjectOptions,
  selectSelectedProjectAndChildProjectOptions,
  selectPersonalWorkCalendarProjectOptions,
  selectIsPersonalWorkCalendarMode,

  (
    externalProjects,
    ownProjects,
    personalProjects,
    isPersonalWorkCalendarMode
  ) => {
    if (isPersonalWorkCalendarMode) {
      return personalProjects;
    } else {
      return [...externalProjects, ...ownProjects];
    }
  }
);

export const selectCurrentProjectOnlyProjectOption = createSelector(
  selectSelectedProjectId,
  selectAllProjectOptions,
  (currentProjectId, projectOptions) => {
    if (projectOptions.length < 1) {
      return true;
    } else {
      return projectOptions.length === 1 &&
        projectOptions[0].id === currentProjectId
        ? true
        : false;
    }
  }
);

export const selectResourceUserOptions = createSelector(
  selectProjectAndChildProjectResourcesWithoutJoinedData,
  selectResourcesWithSelectedProjectAsEmployer,
  selectProjects,
  selectSelectedProjectIds,
  selectSelectedEmployersBusinessIds,
  selectEmployerProjectEntities,
  (
    projectAndChildProjectResources,
    employerResources,
    projects,
    selectedProjectIds,
    selectedEmployersBusinessIds,
    employers
  ) => {
    const resources = [
      ...projectAndChildProjectResources,
      ...employerResources,
    ];
    const isSelectedProjects = selectedProjectIds.length > 0;
    const isSelectedEmployers = selectedEmployersBusinessIds.length > 0;
    const resourceOptionsUniqued = uniqueResourcesByUserAndEmployer(
      resources.filter(
        (resource) =>
          resource.project &&
          (isSelectedProjects
            ? selectedProjectIds.includes(resource.project)
            : true) &&
          (isSelectedEmployers
            ? selectedEmployersBusinessIds.includes(
                employers[resource?.employer]?.business_id
              )
            : true)
      )
    );

    let employerBusinessIds = {};
    const resourceOptions = resourceOptionsUniqued.map((resource) => {
      const employer = employers[resource.employer];

      const employerBusinessId =
        employers &&
        employer &&
        employer.business_id &&
        employer.businessTypeEnum === 'EMPLOYER'
          ? employer.business_id
          : false;

      let employerName =
        employers && employer && employer.name ? employer.name : 'MyLixani';

      const employerBusinessIdAlreadyUsed = employerBusinessIds[
        employerBusinessId
      ]
        ? true
        : false;

      if (employerBusinessId && employerBusinessIdAlreadyUsed) {
        employerName = employerBusinessIds[employerBusinessId];
      } else {
        if (employerBusinessId) {
          employerBusinessIds[employerBusinessId] = employerName;
        }
      }

      return {
        id: resource.user,
        label: `${resource.lastName} ${resource.firstName}`,
        type: employerName,
      };
    });

    resourceOptions.sort((a, b) => sort.sortByKey(a, b, 'type'));

    return resourceOptions;
  }
);

export const selectEmployerOptions = createSelector(
  selectEmployerProjectsArray,
  selectProjectsDataResourcesArray,
  selectSelectedProjectIds,
  (employers, resources, selectedProjectIds) => {
    const isSelectedProjects = selectedProjectIds.length > 0;

    const recordsResourcesEmployersIds = resources
      .filter(
        (resource) =>
          resource.employer &&
          (isSelectedProjects
            ? selectedProjectIds.includes(resource.project)
            : true)
      )
      .map((resource) => resource.employer);

    let employerOptions = uniqBy(
      employers.filter(
        (employer) =>
          employer.businessTypeEnum === 'EMPLOYER' &&
          recordsResourcesEmployersIds.includes(employer.id)
      ),
      'business_id'
    ).map((employer) => {
      return {
        value: employer.business_id,
        label: employer.name,
        type: 'all',
      };
    });

    employerOptions.sort((a, b) => sort.sortByKey(a, b, 'label'));

    return employerOptions;
  }
);

export const selectProjectOptionsWithAssignments = createSelector(
  selectAllProjectOptions,
  selectAssignmentsArrayWithoutDeleted,
  selectSelectedProjectIds,
  (projectOptions, assignments, selectedProjectIds) => {
    const assignmentsProjectIds = assignments.map((a) => a.project);

    const isSelectedProjects = selectedProjectIds.length > 0;

    const projectOptionsWithAssignments = projectOptions.filter(
      (projectOption) =>
        assignmentsProjectIds.includes(projectOption.id) &&
        (isSelectedProjects
          ? selectedProjectIds.includes(projectOption.id)
          : true)
    );

    return projectOptionsWithAssignments;
  }
);

export const selectNestedAssignmentOptionsByProject = createSelector(
  selectAssignmentsArrayWithoutDeleted,
  selectProjects,
  selectSelectedProjectIds,
  selectAllProjectOptions,
  (_, args) => args,

  (assignments, projects, selectedProjectIds, projectOptions, args) => {
    const projectId = args;

    const isSelectedProjects = selectedProjectIds.length > 0;

    const projectOptionsIds = projectOptions.map(
      (projectOption) => projectOption.id
    );

    let assignmentOptions = assignments
      .filter(
        (assignment) =>
          (isSelectedProjects
            ? selectedProjectIds.includes(assignment.project)
            : true) &&
          projectOptionsIds.includes(assignment.project) &&
          assignment.project === projectId
      )
      .map((assignment) => {
        const assignmentProjectName =
          projects[assignment.project] && projects[assignment.project].name
            ? projects[assignment.project].name
            : '';
        return {
          id: assignment.id,
          value: assignment.id,
          parent: assignment.parent ? assignment.parent : false,
          label: `${nestedAssignmentNumber(assignment, assignments)}. ${
            assignment.name
          }`,
          type: assignmentProjectName,
          project: assignment.project,
        };
      });
    assignmentOptions.sort((a, b) => sort.sortByKey(a, b, 'label'));

    const assignmentOptionsParents = assignmentOptions.filter((a) => !a.parent);

    const nestedAssignmentOptions = nestedAssigmentTree(
      assignmentOptionsParents,
      assignmentOptions
    );

    return nestedAssignmentOptions;
  }
);

export const selectWorkCalendarSearchFilters = createSelector(
  [
    selectSelectedEmployersBusinessIds,
    selectSelectedProjectIds,
    selectSelectedUserIds,
  ],
  (businessIds, projectIds, userIds) => ({
    businessIds,
    projectIds,
    userIds,
  })
);

export const selectIsActiveSearchFilters = createSelector(
  [selectWorkCalendarSearchFilters],
  (searchFilters) => {
    const { businessIds, projectIds, userIds } = searchFilters;

    return businessIds.length > 0 || projectIds.length > 0 || userIds.length > 0
      ? true
      : false;
  }
);

// selectors for selectedRecordsEditDialog

export const selectProjectsWithSameEmployerAsSelectedProjectWithoutEmployerProject =
  createSelector(
    [selectWorkCalendarProjectsArray, selectSelectedProject],

    (projects, selectedProject) => {
      const isSelectedProjectEmployerProject =
        selectedProject.businessTypeEnum === 'EMPLOYER';

      const projectsUserIsCreatorOfOrHasSameEmployerAsSelectedProject =
        projects.filter((project) => {
          const isEmployerProject = project.businessTypeEnum === 'EMPLOYER';

          const selectedProjectEmployer = isSelectedProjectEmployerProject
            ? selectedProject.id
            : selectedProject.masterParent;

          const isProjectFromSameEmployer =
            selectedProjectEmployer &&
            selectedProjectEmployer === project.masterParent;
          return isProjectFromSameEmployer && !isEmployerProject;
        });

      const projectsUserIsCreatorOfOrHasSameEmployerAsSelectedProjectSorted =
        orderBy(
          projectsUserIsCreatorOfOrHasSameEmployerAsSelectedProject,
          [(project) => project.name.toLowerCase()],
          ['asc']
        );

      return projectsUserIsCreatorOfOrHasSameEmployerAsSelectedProjectSorted
        ? projectsUserIsCreatorOfOrHasSameEmployerAsSelectedProjectSorted
        : [];
    }
  );

export const selectWorkCalendarProjectsArrayWithJoinedData = createSelector(
  [
    selectWorkCalendarProjectsArray,
    selectEmployerProjectsArray,
    selectProjects,
    selectEmployerProjectEntities,
  ],
  (projectsArray, employersArray, projectsEntities, employersEntities) => {
    const allProjectsArray = uniqBy(
      [...projectsArray, ...employersArray],
      'id'
    );
    const allProjectEntities = { ...projectsEntities, ...employersEntities };

    const joinedDataProjects = allProjectsArray.map((project) => ({
      ...project,
      __typename: 'Project',
      masterParent: allProjectEntities[project.masterParent]
        ? allProjectEntities[project.masterParent]
        : { id: project.masterParent },
      parent: allProjectEntities[project.parent]
        ? allProjectEntities[project.parent]
        : { id: project.parent },
    }));
    return joinedDataProjects;
  }
);

export const selectProjectsEntitiesWithJoinedData = createSelector(
  [selectWorkCalendarProjectsArrayWithJoinedData],
  (projects) => arrayToMap(projects)
);

export const selectWorkCalendarResourcesArrayWithJoinedData = createSelector(
  [selectProjectsDataResourcesArray, selectEmployerProjectEntities],
  (resources, employers) =>
    resources.map((resource) => ({
      ...resource,
      employer: employers[resource.employer]
        ? employers[resource.employer]
        : { id: resource.employer },
    }))
);

export const selectCurrentProjectsAndChildProjectsResources = createSelector(
  [
    selectWorkCalendarResourcesArrayWithJoinedData,
    selectSelectedProject,
    selectWorkCalendarProjectsArrayWithJoinedData,
  ],
  (resources, selectedProject, projects) => {
    const childProjectIds = projects
      .filter(
        (project) =>
          project.masterParent.id === selectedProject.id ||
          project.parent.id === selectedProject.id
      )
      .map((project) => project.id);

    return resources.filter((resource) => {
      // There is resources which have normal project as employer
      const isResourceEmployerProjectEmployerProject =
        resource.employer.businessTypeEnum === 'EMPLOYER';
      const resourceHasEmployer = resource.employer && resource.employer.id;
      const isResourceFromSelectedProject =
        resource.project === selectedProject.id;
      const isResourceFromChildProjects = childProjectIds.includes(
        resource.project
      );

      return (
        (isResourceFromSelectedProject || isResourceFromChildProjects) &&
        (isResourceEmployerProjectEmployerProject || !resourceHasEmployer)
      );
    });
  }
);

export const selectCurrentProjectsAndChildProjectsResourcesUnique =
  createSelector(
    [selectCurrentProjectsAndChildProjectsResources],
    (resources) => {
      const uniqueResources = uniqWith(
        resources,
        (a, b) => a.user === b.user && a.employer.id === b.employer.id
      );

      const uniqueAndSortedResource = orderBy(
        uniqueResources,
        [
          (resource) =>
            resource.employer && resource.employer.name
              ? resource.employer.name.toLowerCase()
              : 'myLixani'.toLowerCase(),
        ],
        ['asc']
      );

      return uniqueAndSortedResource;
    }
  );

function nestedName(project, projects) {
  //adds parent names to return value until master parent (employer) name is found. example of final return value for when project param is a subproject:
  // "employerName / projectName / subprojectName"
  let name = project.name;
  if (project.parent) {
    let parent = projects.find((p) => p.id === project.parent);
    if (parent) {
      name = nestedName(parent, projects) + ' / ' + name;
    }
  }
  return name;
}

function nestedAssignmentNumber(assignment, assignments) {
  let number = assignment.number;
  if (assignment.parent) {
    let parent = assignments.find((p) => p.id === assignment.parent);
    if (parent) {
      number = nestedAssignmentNumber(parent, assignments) + '.' + number;
    }
  }
  return number;
}

function nestedAssigmentTree(assignmentParents, allAssignments) {
  function nestedAssignments(parentAssignment, assignments) {
    const assignmentChildren = assignments.filter(
      (a) => a.parent === parentAssignment.id
    );
    const assignmentHasChildren = assignmentChildren.length > 0;

    let children = [];

    if (assignmentHasChildren) {
      children = assignmentChildren.map((a) =>
        nestedAssignments(a, assignments)
      );
    }
    let assignmentTree = {
      assignment: parentAssignment,
      children,
    };

    return assignmentTree;
  }

  const nestedAssignmentOptions = assignmentParents.map((a) =>
    nestedAssignments(a, allAssignments)
  );

  return nestedAssignmentOptions;
}

export const selectWorkCalendarRecordIdsUserIsAdminOf = createSelector(
  [selectWorkCalendarRecordsArray, selectProjects, selectUserAdminBusinessIds],
  (records, workCalendarProjects, userAdminBusinessIds) => {
    return records
      .filter((record) => {
        const projectOfRecord = workCalendarProjects[record.project];

        return projectOfRecord
          ? userAdminBusinessIds.includes(projectOfRecord.business_id)
          : false;
      })
      .map((record) => record.id);
  }
);

export const selectSelectedWorkCalendarEmployer = createSelector(
  [selectSelectedEmployersBusinessIds, selectEmployerProjectsArray],
  (selectedBusinessIds, employers) =>
    employers.find(
      (employer) => employer.business_id === selectedBusinessIds[0]
    )
);
