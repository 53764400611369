import axios from 'axios';
import Config from 'Config';
import { createSignedRequest } from '../../../utils/createSignedRequest';

export const postDeleteProject = async (projectId) => {
  
  const apiPath = `projects/${projectId}/delete-project`;


  const request = {
    // url: `http://localhost:3001/${apiPath}`,
    url: `https://${process.env.REACT_APP_WORK_CALENDAR_API_URL}/${apiPath}`,
    host: process.env.REACT_APP_WORK_CALENDAR_API_URL,
    path: apiPath,
    method: 'DELETE',
    region: Config.AWS_REGION,
    service: 'execute-api',
    // headers: {
    //   'Content-Type': 'application/json',
    // },
  };
  const signedRequest = await createSignedRequest(request);

  const response = await axios(signedRequest);

  return response.data;
};
