import { createAsyncThunk } from '@reduxjs/toolkit';
import { postCreateProject } from '../services/postCreateProject.service';

export const createProject = createAsyncThunk(
  'projects/createProject',
  async (data) => {
    const projectData = data.projectData;
    const assignments = data.assignments;
    const projectCreationData = await postCreateProject(projectData, assignments);

    return projectCreationData;
  }
);
