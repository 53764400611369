import { createAsyncThunk } from '@reduxjs/toolkit';
import { postUpdateProject } from '../services/postUpdateProject.service';
import { postDeleteProject } from '../services/postDeleteProject.service';

export const deleteProject = createAsyncThunk(
  'projects/deleteProject',
  async (projectId) => {
    const projectUpdateData = await postDeleteProject(projectId);

    return projectUpdateData;
  }
);
