import { createAsyncThunk } from '@reduxjs/toolkit';
import { postUpdateProject } from '../services/postUpdateProject.service';

export const updateProject = createAsyncThunk(
  'projects/updateProject',
  async (projectData) => {
    const projectUpdateData = await postUpdateProject(projectData);

    return projectUpdateData;
  }
);
