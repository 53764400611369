import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../../../utils/getCredentials';
import setDeleteFiling from 'scenes/Filings/store/services/setDeleteFiling.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const deleteFiling = createAsyncThunk(
  'filings/deleteFiling',
  async (filingId, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    if (!filingId) {
      return;
    }

    const state = getState();

    const projectId = selectSelectedProjectId(state);

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    const deletedFiling = await setDeleteFiling(filingId, projectId, params);
    return { deletedFiling };
  }
);
