import { createAsyncThunk } from '@reduxjs/toolkit';
import { postNewCompetencyType } from '../services/post-new-competency-type.service';

export const addNewCompetencyType = createAsyncThunk(
  'userProfile/addNewCompetencyType',
  async (competencyData) => {
    const createdCompetencies = await postNewCompetencyType(competencyData);

    return createdCompetencies;
  }
);
