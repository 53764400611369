import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../../../utils/getCredentials';
import getFilings from 'scenes/Filings/store/services/getFilings.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const loadFilings = createAsyncThunk(
  'filings/loadFilings',
  async (project_id, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();
    const state = getState();

    let projectId = selectSelectedProjectId(state);

    if (!projectId) {
      projectId = project_id;
    }

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    let taxfilings = [];

    let response;

    // Get all filings for the project using lastEvaluatedKey
    do {
      response = await getFilings(projectId, response?.LastEvaluatedKey, params);
      taxfilings.push(...response.Items);
    } while (response.LastEvaluatedKey);

    const filings = [].concat(...taxfilings);

    return {
      filings,
    };
  }
);
