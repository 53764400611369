import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../../../utils/getCredentials';
import setUpdateFiling from 'scenes/Filings/store/services/setUpdateFiling.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const updateFiling = createAsyncThunk(
  'filings/updateFiling',
  async (filing, { getState }) => {
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getCredentials();

    const state = getState();

    const projectId = selectSelectedProjectId(state);

    const params = {
      accessKeyId,
      secretAccessKey,
      sessionToken,
    };

    const updatedFiling = await setUpdateFiling(filing, projectId, params);
    return { updatedFiling };
  }
);
