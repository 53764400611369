import { createAsyncThunk } from '@reduxjs/toolkit';
import getCredentials from '../../../../utils/getCredentials';
import { getCompanies } from '../services/getCompanies.service';

export const loadCompanies = createAsyncThunk(
    'filings/loadCompanies',
    async (business_id, { getState }) => {
        const { accessKeyId, secretAccessKey, sessionToken } =
            await getCredentials();
        const state = getState();

        const params = {
            accessKeyId,
            secretAccessKey,
            sessionToken,
        };

        let companies = [];

        let response;

        // Get all filings for the project using lastEvaluatedKey
        do {
            response = await getCompanies(business_id, response?.LastEvaluatedKey, params);
            companies.push(...response.Items);
        } while (response.LastEvaluatedKey);

        const contractCompanies = [].concat(...companies);

        return {
            companies: contractCompanies,
        };
    }
);