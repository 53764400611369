import { createAsyncThunk } from '@reduxjs/toolkit';
import { patchDeleteCompetencyType } from '../services/patchDeleteCompetencyType';

export const deleteCompetencyType = createAsyncThunk(
  'cards/deleteCompetencyType',
  async (competencyId) => {
    const response = await patchDeleteCompetencyType(competencyId);

    return response;
  }
);
