import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  selectAssignmentModifications,
  selectChildAssignmentsToAddWithModifications,
  selectRootAssignmentsToAddArrayWithModifications,
} from '../selectors/assignment.selectors';
import { postCreateAndUpdateProjectAssignments } from '../services/postCreateAndUpdateProjectAssignments.service';
import { selectSelectedProjectId } from 'store/projects/selectors/projectsSelectors';

export const saveCreatedAndUpdatedProjectAssignments = createAsyncThunk(
  'assignments/saveCreatedAndUpdatedProjectAssignments',
  async (data) => {
    // only update/create modified assignments
    console.log('data', data);
    const { projectId, createdAssignments, updatedAssignments } = data;

    const createdAndModifiedAssignments = postCreateAndUpdateProjectAssignments(
      projectId,
      createdAssignments,
      updatedAssignments
    );

    return createdAndModifiedAssignments;
  }
);
