/**
 * Process data from filePermissionList query
 * It creates child-parent and isRoot data
 * @param {Array<object>} dataset Array of FilePermission objects
 * @param {string} urlPath User ID
 * @returns {object} Processed tree object of FilePermission objects
 */
const processTree = (dataset, urlPath) => {
  let hashTree = {};
  dataset.forEach((item) => {
    const duplicates = dataset.filter((dItem) => dItem.path === item.path);
    if (item.path && !(item.path in hashTree)) {
      if (duplicates.length > 1) {
        hashTree[item.path] = { ...duplicates[0], children: [] };
      } else {
        hashTree[item.path] = { ...duplicates[0], children: [] };
      }
    }
  });

  /**
   * Checks if FilePermission object is a root or a child
   */
  Object.keys(hashTree).forEach((key) => {
    if (!hashTree[hashTree[key].parentPath] || hashTree[key].path === urlPath) {
      hashTree[key].isRoot = true;
    } else if (key) {
      hashTree[hashTree[key].parentPath].children.push(key);
    }
  });
  return hashTree;
};

export default processTree;
