import { sortByLastName } from './sort';

export const fetchReportDataFromRecords = async (
  project,
  recordsToGenerateReportFrom,
  resourcesFromRecordsToGenerateReportFrom,
  projects,
  assignments,
  users,
  recordAllowancesGroupedByRecordId,
  generationParams
) => {
  const recordsWithJoinedData = recordsToGenerateReportFrom.map((record) => ({
    ...record,
    resource: resourcesFromRecordsToGenerateReportFrom.find(
      (resource) => record.resource === resource.id
    ),
    project: projects.find((project) => record.project === project.id),
    assignment: assignments.find(
      (assignment) => record.assignment === assignment.id
    ),
  }));

  const sortedRecords = recordsWithJoinedData.sort((a, b) =>
    sortByLastName(a.resource, b.resource, 'fi')
  );

  return {
    resources: resourcesFromRecordsToGenerateReportFrom.flat(),
    records: sortedRecords,
    users,
    projectName: project.name,
    assignments,
    utcOffset: generationParams.utcOffset || '+00:00',
    workStartTime: generationParams.workStartTime || '00:00',
    workEndTime: generationParams.workEndTime || '23:59',
    workHourType: generationParams.workHourType || 'ALL',
    fromDate: generationParams.fromDate,
    toDate: generationParams.toDate,
    reportType: generationParams.selectedReportType,
    recordAllowancesGroupedByRecordId,
  };
};
